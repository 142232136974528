import React from "react";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";

const RoundChart = ({pca, cdpap,officeMessage}) => {

  // console.log(pca, cdpap, officeMessage)

  const data = [
 
  { name: "PCA", value: pca  },
   { name: "CDPAP", value: cdpap },
  { name: "OFFICE", value: officeMessage},
  
];

const COLORS = ["#00C49F","#0088FE",  "#FFBB28"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
  name
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
  return (
    <PieChart width={300} height={300}>
    <Pie
      data={data}
      cx={120}
      cy={120}
      labelLine={false}
      label={renderCustomizedLabel}
      outerRadius={120}
      fill="#8884d8"
      dataKey="value"
      Label ="name"    
    >
      {data.map((entry, index) => (
        <Cell
         key={`cell-${index}`}
         fill={COLORS[index % COLORS.length]}
         nameKey="name"
         
         />

         
      ))}
    </Pie>
   
  </PieChart>
  );
};

export default RoundChart;
