import React, { useState } from "react";
import { Virtual, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import './CottageVideoSlider.css'



// Import images

import img2 from "../../../../assets/slider/allVideos/img2.jpg";
import img3 from "../../../../assets/slider/allVideos/img3.jpg";
import img5 from "../../../../assets/slider/allVideos/img5.jpg";
import img6 from "../../../../assets/slider/allVideos/img6.jpg";
import img7 from "../../../../assets/slider/allVideos/img7.jpg";
import img8 from "../../../../assets/slider/allVideos/img8.jpg";
import img9 from "../../../../assets/slider/allVideos/img9.jpg";
import img10 from "../../../../assets/slider/allVideos/img10.jpg";
import img11 from "../../../../assets/slider/allVideos/img11.jpg";
import img12 from "../../../../assets/slider/allVideos/img12.jpg";
import img13 from "../../../../assets/slider/allVideos/IMG13.jpg";
import img14 from "../../../../assets/slider/allVideos/img14.jpg";
import img15 from "../../../../assets/slider/allVideos/img15.jpg";
import img16 from "../../../../assets/slider/allVideos/img16.jpg";
import { CottageVideoSliderButton } from "../../Services/Services/SliderButton/CottageVideoSliderButton";
import CottageVideoModal from "../../Videos/CottageVideoModal";


const allVideos = [
  {
    des: "The Spicy Wings Challenge: Who Will Cry First? 🌶️👀 | Sponsored By Cottage Home Care | Friday Fun Day",
    duration: "6:01",
    img: img16,
    youtubeUrl:
      "https://www.youtube.com/embed/MBCTyo9OmCE?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  },
  {
    des: "She ALMOST had it, OMG | Taco Challenge | Friday Fun Day Food Challenge | Cottage Home Care",
    duration: "3:33",
    img: img12,
    youtubeUrl:
      "https://www.youtube.com/embed/12vz6yxxGSU?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  },
  {
    des: "Pizza Pie Challenge l Loser Gets Penalty | Friday Fun Day Food Challenge | Cottage Home Care",
    duration: "4:19",
    img: img8,
    youtubeUrl:
      "https://www.youtube.com/embed/dV6t9P00voE?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  },
  {
    des: "WHAT JUST HAPPENED! No Way! - Taco Challenge | Sponsored by Cottage Home Care Services | Friday Fun!",
    duration: "5:18",
    img: img15,
    youtubeUrl:
      "https://www.youtube.com/embed/kxZTjxCb5Os?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  },
  {
    des: "Risk or Reward Challenge! | India vs Guyana | $300 On the Line! Sponsored by Cottage Home Care",
    duration: "9:26",
    img: img14,
    youtubeUrl:
      "https://www.youtube.com/embed/ZdJE8sCJFbM?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  },
  {
    des: " 'Gwaan, give up nuh bredda' | Friday Fun Day Food Challenge |  Brooklyn Institute of Vocational Training ",
    duration: "5:17",
    img: img13,
    youtubeUrl:
      "https://www.youtube.com/embed/2q65v3R-YHA?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  },
  {
    des: "Guess the Chocolate Blindfolded & Separation Anxiety | Friday Fun Day Food Challenge | Cottage Home Care",
    duration: "6:02",
    img: img10,
    youtubeUrl:
      "https://www.youtube.com/embed/VCQJliEFxy8?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  },
  {
    des: "OMG - Don't pick THAT cup! | Friday Fun Day Challenge | Cottage Home Care",
    duration: "6:02",
    img: img11,
    youtubeUrl:
      "https://www.youtube.com/embed/mauJBeWAjvM?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  },
  {
    des: "Balloon Challenge, Best Out of 3 Rounds | $300 Prize | Friday Fun Day Food Challenge | Cottage Home Care",
    duration: "2:20",
    img: img9,
    youtubeUrl:
      "https://www.youtube.com/embed/RWeQ3cz2YW0?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  },
  // {
  //   des: "Friday Funday Food Chllange By Cottage home Care Services Winners get $500",
  //   duration: "4:58",
  //   img: img2,
  //   youtubeUrl:
  //     "https://www.youtube.com/embed/9t48a9Ckc8o?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  // },
  // {
  //   des: "Match The Bottle Challenge, Friday Funday Food Challenge ",
  //   duration: "3:07",
  //   img: img3,
  //   youtubeUrl:
  //     "https://www.youtube.com/embed/5Ta0VDZmbNY?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  // },
  {
    des: "Who can eat the most? Friday Funday Food Challenge ",
    duration: "2:39",
    img: img6,
    youtubeUrl:
      "https://www.youtube.com/embed/rmL7mpbjDNM?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  },
  {
    des: "Behind the scenes - Who can eat the most? Friday Funday Food Challenge",
    duration: "0:35",
    img: img7,
    youtubeUrl:
      "https://www.youtube.com/embed/qWMiLZpoHK8?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  },
  {
    des: "Chow Challenge, Friday Funday Food Challenge ",
    duration: "0:56",
    img: img5,
    youtubeUrl:
      "https://www.youtube.com/embed/YJ-9CiyKj0I?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  },
];

export default function App() {

 

  const [youtubeUrl, setYoutubeUrl] = useState("");

  const [showModal, setShowModal] = useState(false);

  const openModal = (video) => {
    setShowModal(true);
    setYoutubeUrl(video?.youtubeUrl);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="carousel-bg  py-5" >
        
        <div className="w-[100%]">
        <div data-aos="fade-up" data-aos-duration="1500">
        <div className="w-[95%] lg:w-[80%] 2xl:w-[70%] mx-auto dark:text-white mt-[10px] md:mt-[20px] lg:mt-10 ">
        <h1 className=" text-start text-2xl lg:text-3xl font-semibold league-spartan text-primary dark:text-gray-100 lg:max-w-[840px] ">
          {" "}
          Our Fun Day Challenges: Where Competition Meets Community!
        </h1>
        <p className="mt-1 md:mt-3 w-[95%] lg:max-w-[865px]  text-sm md:text-base  leading-loose  text-[16px] open-sans font-normal text-[#373642] dark:text-gray-100 text-justify" 
        
        >
        Get ready for nonstop fun, exciting challenges, and belly-aching laughter! Our Fun Day Challenges are packed with unique, culturally inspired competitions that bring everyone together. From aides and staff to students and beyond, we’re all about creating unforgettable memories—and giving them all the chance to win big with cash prizes.
        </p>
        <p className="mt-2 2xl:mt-5 text-xl  lg:text-xl 2xl:text-2xl font-semibold text-start league-spartan text-primary dark:text-gray-100">
        Watch Now & Stay Tuned for More Excitement!
        </p>
      </div>
      <div
        className="flex cottageAllVideo-swiper-page  relative lg:pb-10 "
        style={{  overflow: "hidden" }}
      >
        <Swiper
          modules={[Virtual, Pagination,Autoplay]}
          slidesPerView={3}
          centeredSlides={false}
          spaceBetween={40}
          pagination={{
            type: "fraction",
          }}
          virtual
          grabCursor={true}
          autoplay={{
            delay: 3000, // Delay between slides in ms (adjust as needed)
            disableOnInteraction: false, // Continue autoplay even after user interaction
          }}
          loop={true} // Enable looping
          className="w-[90%] md:w-[95%] lg:w-[80%] 2xl:w-[70%]   2xl:h-[330px] h-[280px] lg:h-[250px] cottage-all-videos "
          breakpoints={{
            // when window width is >= 320px
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            // when window width is >= 768px
            768: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            // when window width is >= 1024px
            1024: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            
          }}
        >
          {allVideos?.map((video, index) => (
            <SwiperSlide
              onClick={() => openModal(video)}
              className="  cottage-video-slider  h-[180px]  lg:h-[160px] 2xl:h-[220px]  mt-[35px] md:mt-[30px] 2xl:mt-[35px]  "
              key={index}
              virtualIndex={index}
            >
              <div
                className="cottage-video-image  h-[180px] lg:h-[160px] 2xl:h-[220px] relative"
                style={{
                  backgroundImage: `url(${video?.img})`,
                  backgroundColor: "transparent",
                  backgroundSize: "cover",
                  width: "100%",
                }}
              >
                <p className="bg-[#000000d8] px-2 py-0.5 text-sm text-white absolute top-2 left-2">
                  {video?.duration}
                </p>

                <p className="cottage-video-description  text-[16px] leading-relaxed open-sans font-normal">{video.des}</p>
              </div>
            </SwiperSlide>
          ))}

          <div className="top-0 right-0 absolute">
            <CottageVideoSliderButton/>
          </div>
        </Swiper>
      </div>
        </div>
      
      {showModal ? (
        <CottageVideoModal youtubeUrl={youtubeUrl} onClose={closeModal} />
      ) : null}
        </div>
   
    </div>
  );
}
