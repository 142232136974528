import React, { useState } from 'react';
import { MdOutlineKeyboardDoubleArrowUp } from 'react-icons/md';
import { Button } from './Scroll';

const ScrollButton = () =>{

const [visible, setVisible] = useState(false)

const toggleVisible = () => {
	const scrolled = document.documentElement.scrollTop;
	if (scrolled > 2000){
	setVisible(true)
	}
	else if (scrolled <= 2000){
	setVisible(false)
	}
};

const scrollToTop = () =>{
	window.scrollTo({
	top: 0,
	behavior: 'smooth'
	/* you can also use 'auto' behaviour
		in place of 'smooth' */
	});
};

window.addEventListener('scroll', toggleVisible);

return (
	<Button>
	<MdOutlineKeyboardDoubleArrowUp onClick={scrollToTop}
	style={{display: visible ? 'inline' : 'none'}} className='bg-primary text-white h-7 w-7 md:h-9 md:w-10 md:p-2 shadow-lg text-base md:text-xl'/>
	</Button>
);
}

export default ScrollButton;
