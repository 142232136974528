import React, { useContext } from "react";
import "./RecourcesCard.css";
import Loading from "../../Shared/Loading/Loading";
import useAdmin from "../../Hooks/UseAdmin";
import { AuthContext } from "../Context/AuthProvider";
import cdpapApplication from "../../../assets/Files/CDPAP Completed Application.pdf";
import physicalForm from "../../../assets/resources/PHYSICAL Form.pdf";
import dayliTimeSheet from "../../../assets/resources/Daily Time sheet.pdf";
import celestial from "../../../assets/resources/chelestial timesheet.pdf";
import directDeposite from "../../../assets/resources/direct deposite.pdf";
import timeOff from "../../../assets/resources/time off.pdf";
import timeOffFillable from "../../../assets/resources/time off fillable.pdf";
import doh from "../../../assets/resources/DOH.pdf";
import { Link } from "react-router-dom";

const ResourcesCard = () => {
  const { user } = useContext(AuthContext);

  const [isAdmin] = useAdmin(user?.email);

  const handleOpenPdfClick = (pdf) => {
    // Replace this with your actual PDF file or provide a way for the user to select a file
    window.open(pdf, "_blank");
  };


  const allDocuments = [

{

name:"Physical Form (PDF)",
file: physicalForm,

},
{

name:"Daily Timesheet (PDF)",
file: dayliTimeSheet,

},
{

name:"Celestial Timesheet (PDF)",
file: celestial,

},
{

name:"DOH (PDF)",
file: doh,

},
{

name:"DIRECT DEPOSIT AUTHORIZATION (PDF)",
file: directDeposite,

},
{

name:"Time-off Form (PDF)",
file: timeOff,

},
{

name:"Time-off Form Fillable (PDF)",
file: timeOffFillable,

},
{

name:"Completed CDPAP Application (PDF)",
file: cdpapApplication,

},

  ]




  return (
    <div className="py-10 min-h-screen bg-[#EBF8F9] px-5 dark:bg-slate-600">
      <h1 className="text-center league-spartan md:text-xl lg:text-2xl xl:text-4xl text-xl font-bold text-primary ">
        {" "}
        Downloads{" "}
      </h1>
      <hr className="border-[#00a6b2] border-t-[1px]  w-12 mt-2  mb-10 mx-auto" />

      {/* {pdfs.length <= 0 && (
        <div className="my-10">
          <Loading></Loading>
        </div>
      )} */}

      <div
        className="grid md:grid-cols-2 lg:grid-cols-3 max-w-7xl mx-auto gap-7"
        id="all-resources"
      >
        {/* {pdfs?.map(
          (pdf) => (
            <div
              key={pdf?._id}
              className="card w-80 lg:w-96 bg-base-100 shadow-xl mx-auto mt-5 bg-hov3 dark:bg-slate-800 hover:bg-primary hover:text-white main-card bg-hov2 dark:hover:bg-primary relative"
            >
              <div
                className="card-body mb-5"
                data-aos="zoom-in"
                data-aos-duration="1000"
              >
                <h2 className="text-xl font-normal text-center open-sans dark:text-gray-100">
                  {pdf?.filename} (PDF)
                </h2>

                <div className="card-actions justify-center mt-6 ">
                  <button
                    onClick={() => viewPdf(pdf)}
                    target="_blank"
                    className=" custom-ancor  bg-primary text-white px-5 py-2.5
                            open-sans  rounded-md shadow-md"
                    rel="noreferrer"
                  >
                    Download
                  </button>
                </div>
              </div>
              {isAdmin && (
                <div className=" bg-primary flex justify-between absolute bottom-0 w-full rounded-md text-sm text-gray-100 py-1 hover:bg-orange-500">
                  <button
                    className=" px-5 py-2"
                    onClick={() => deleteHandler(pdf)}
                  >
                    Delete
                  </button>
                  <Link

                  to={`/dashBoard/pdf/${pdf?._id}`}
                    className=" px-5 py-2"
                    
                  >
                    Update
                  </Link>
                </div>
              )}
            </div>
          )
          
        )} */}

        {

          allDocuments?.map((doc,index)=><div
          key={index}
          
          className="card w-80 lg:w-96 bg-base-100 shadow-xl mx-auto mt-5 bg-hov3 dark:bg-slate-800 hover:bg-primary hover:text-white main-card bg-hov2 dark:hover:bg-primary relative">
          <div
            className="card-body mb-5"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <h2 className="text-xl font-normal text-center open-sans dark:text-gray-100 open-sans">
              {doc?.name}
            </h2>

            <div className="card-actions justify-center mt-6 ">
              <button
                onClick={() => handleOpenPdfClick(doc?.file)}
                className=" custom-ancor  bg-primary text-white px-5 py-2.5
                  open-sans  rounded-md shadow-md"
              >
                Download
              </button>
            </div>
          </div>
          {/* {isAdmin && (
            <button className="bg-primary absolute bottom-0 w-full rounded-md text-sm text-gray-100 py-1 hover:bg-orange-500">
              Delete
            </button>
          )} */}
        </div>)
        }

        
       
      </div>
    </div>
  );
};

export default ResourcesCard;
