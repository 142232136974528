import React, { useContext } from 'react'
import './BrooklyenRegistration.css'
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

const BrooklynRegistration = () => {



    const date = Date.now();

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    const submitHandler = (data) => {

        const message = {
            firstName: data.firstName,
            email: data.email,
            phone: data.phone,
            messages: data.message,
            time: date,
        };

        // console.log(message)

        fetch(
            "https://cottage-updated-server-v3.vercel.app/registration",
            {
                method: "PUT",
                headers: {
                    "content-type": "application/json",
                },
                body: JSON.stringify(message),
            }
        )
            .then((res) => res.json())
            .then((data) => {
                if (data.acknowledged) {

                    reset();
                    toast.success("Registration Successfully Completed");

                }

            });


    }


    return (
        <div className='min-h-screen dark:bg-slate-600'>

            <div className='bg-cover bg-center text-center min-h-[80vh] brooklyn-banner'>
                <h1 className='text-xl md:text-5xl font-semibold  pt-[55vh] text-white text-shadow'>
                Register for Tuition Free Training  From Brooklyn Institute </h1>
            </div>


            {/* registration form start  */}

            <div className='lg:w-[70%] w-[95%] mx-auto py-20'>
                <div className='bg-[#E9EDF0] px-3 md:px-6 py-3 md:py-12 dark:bg-slate-800 shadow-right z-10 shadow-lg rounded-md'>

                    <div className=' py-4 lg:w-[90%] mx-auto w-full px-2 '>
                        <h3 className='text-xl lg:text-4xl font-semibold dark:text-gray-100 mb-6 text-center '><u>Tuition Free HHA training </u></h3>

                        <div className='border-[1px] border-primary rounded-md shadow-md px-4 py-4 mb-10 '>
                        <h3 className='text-center text-lg font-semibold'>
                        📢  Exciting News! 🎉  
                        </h3>

                        <p className='my-2 lg:text-center text-justify font-medium '>
                        Cottage Home Care Services & The Brooklyn Institute of Vocational Training are thrilled to announce our new tuition-free program that brings excellent opportunities to aspiring home health aides! 🏥 👩‍⚕️ 
                        </p>

                        <p className='lg:text-center text-justify hype font-medium  my-2'>
                        We understand that pursuing a healthcare career can be challenging, especially with financial constraints. 
                        </p>
                        <p className='lg:text-center text-justify hype font-medium  '>
                        HOW? Enter the required information below and a selection process will he held, after which 6 persons will be selected for the TUITION-FREE training. 
                        </p>

                        </div>
                        <form

                            onSubmit={handleSubmit(submitHandler)}

                        >

                            <div className='  flex items-center gap-5 md:gap-10'>

                                <div className="  w-full">
                                    <label htmlFor="firstName" className="block dark:text-gray-100 my-1 text-base font-bold uppercase ">
                                        Full Name
                                    </label>
                                    <input

                                        {...register("firstName", {
                                            required: "Name is required",
                                        })}
                                        type="text"
                                        id="firstName"
                                        placeholder="Full Name"
                                        className="w-full px-4 py-3  border text-gray-700 focus:outline-primary bg-white
                                    focus:bg-white shadow-md dark:bg-gray-400 dark:placeholder:text-gray-100 dark:text-gray-100 placeholder:uppercase"
                                    />

                                    {errors.firstName && (
                                        <p className="text-red-600">{errors.firstName.message}</p>
                                    )}

                                </div>

                                <div className="w-full">
                                    <label htmlFor="phone" className="block dark:text-gray-100 my-1 text-base font-bold uppercase ">
                                        Phone Number
                                    </label>
                                    <input

                                        {...register("phone", {
                                            required: "Phone Number is required",
                                        })}
                                        type="tel"
                                        id="phone"
                                        placeholder="Phone"
                                        className="w-full px-4 py-3  border text-gray-700 focus:outline-primary bg-white
                                                focus:bg-white shadow-md dark:bg-gray-400 dark:placeholder:text-gray-100 dark:text-gray-100 placeholder:uppercase"
                                    />

                                    {errors.phone && (
                                        <p className="text-red-600">{errors.phone.message}</p>
                                    )}


                                </div>

                            </div>
                            <div className='mt-6'>

                                <div className="  w-full">
                                    <label htmlFor="email" className="block dark:text-gray-100 my-1 text-base font-bold  uppercase">
                                        Email Address
                                    </label>
                                    <input

                                        {...register("email", {
                                            required: "Email is required",
                                        })}
                                        type="email"
                                        id="email"
                                        placeholder="Email Address"
                                        className="w-full px-4 py-3  border text-gray-700 focus:outline-primary bg-white
                                                focus:bg-white shadow-md dark:bg-gray-400 dark:placeholder:text-gray-100 dark:text-gray-100 placeholder:uppercase"
                                    />


                                    {errors.email && (
                                        <p className="text-red-600">{errors.email.message}</p>
                                    )}

                                </div>

                            </div>
                            <div className="  w-full mt-6">
                                <label htmlFor="firstName" className="block dark:text-gray-100 my-1 text-base font-bold  uppercase">
                                    Why should we select you for this training?
                                </label>
                                <textarea

                                    {...register("message", {
                                        required: "Message is required",
                                    })}

                                    type="text"
                                    // name="subject"
                                    id="Message"
                                    rows={5}
                                    placeholder="Your Message"
                                    className="w-full px-4 py-3 border  text-gray-700 focus:outline-primary bg-white
                                            focus:bg-white shadow-md dark:bg-gray-400 dark:placeholder:text-gray-100 dark:text-gray-100"
                                />
                                {errors.message && (
                                    <p className="text-red-600">{errors.message.message}</p>
                                )}

                            </div>

                            <div className='w-[70%] mx-auto'>
                                <button className='bg-primary px-4 py-3 rounded-md text-white font-semibold mt-4 shadow-md w-full uppercase'>
                                   Register

                                </button>

                            </div>

                        </form>

                    </div>


                </div>


            </div>


            {/* registration form end */}



        </div>
    )
}

export default BrooklynRegistration
