import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LiaUserNurseSolid } from "react-icons/lia";
import { MdOutlineMedicalServices, MdOutlineStar } from "react-icons/md";
import { TiStarHalfOutline } from "react-icons/ti";
import PropTypes from "prop-types";

import img1 from "../../../../assets/testimonial/1.webp";
import img2 from "../../../../assets/testimonial/2.webp";
import img3 from "../../../../assets/testimonial/3.webp";
import cardCover from "../../../../assets/testimonial/testimonial-cover.webp";
import bluetik from "../../../../assets/testimonial/download.webp";
import LazyLoadVideo from "../../../LazyloadVideo.js/LazyLoadVideo";
import MyImageComponent from "../../../Utils/MyImageComponent";

const VideoCard = ({ src }) => <LazyLoadVideo src={src} rounded={true} />;

VideoCard.propTypes = {
  src: PropTypes.string.isRequired,
};

const TestimonialCard = ({
  image,
  name,
  description,
  designation,
  caseType,
  rating,
}) => (
  <div>
    <div className="relative">
      <MyImageComponent 
        src={cardCover}
        alt="cover"
        className="rounded-t-xl"
        width='1053'
        height='264'
       
      />
      <MyImageComponent 
        src={image}
        alt={name}
        className="w-14 h-14 md:w-24 md:h-24 object-cover shadow-lg rounded-full bg-[#EBF8F9] absolute left-3 -bottom-6 md:-bottom-10 border-[#e47825] border-[2px]"
        width='96'
        height='96'
      />
    </div>
    <div className="mt-7 md:mt-11 px-5">
      <h3 className="text-sm  font-semibold dark:text-gray-100 league-spartan text-[18px]">
        {name}
      </h3>
      <p className="-ml-1 flex items-center md:text-base text-sm md:mt-1 gap-1 font-medium dark:text-gray-100 league-spartan">
        <LiaUserNurseSolid className="md:text-2xl  text-sm font-semibold" />{" "}
        <span className=" font-semibold text-[18px]">Profession</span>{" "}
        <span className="font-bold">:</span>{" "}
        <span className=" font-semibold text-[18px]">{designation}</span>
      </p>
      <p className="-ml-1 flex md:mt-1.5 items-center font-semibold gap-1 dark:text-gray-100 text-sm md:text-base league-spartan ">
        <MdOutlineMedicalServices className="md:text-xl" />
        <span className="text-[18px]"> Service : </span> <span className="text-[18px]">{caseType}</span>
      </p>
      <div className="md:mt-2 mt-2">
        <MyImageComponent 
          src={bluetik}
          alt="verified"
          className="md:w-7 w-5 h-5 md:h-7 dark:bg-white dark:rounded-full"
          width="20"
          height="20"
        />
        <p className="text-justify auto-hyphen  tracking-tight md:tracking-normal dark:text-gray-100 dark:mt-1 text-[13px] md:text-[16px] leading-relaxed open-sans font-normal">
          {description}
        </p>
      </div>
      <div>
        <p className="flex items-center mt-2 text-yellow-500">
          {[...Array(Math.floor(rating))].map((_, i) => (
            <MdOutlineStar className="md:text-xl" key={i} />
          ))}
          {rating % 1 !== 0 && <TiStarHalfOutline className="md:text-xl" />}
        </p>
        <p className="w-20 text-center font-bold mt-1 dark:text-gray-100 md:text-xl">
          {rating}
        </p>
      </div>
    </div>
  </div>
);

TestimonialCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  designation: PropTypes.string.isRequired,
  caseType: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
};

const NewSlider = () => {
  const allData = [
    { src: "https://www.youtube.com/embed/cruhtClBx_I?rel=0" },
    {
      image: img1,
      name: "Cinthya Almendarez",
      designation: "Patient",
      description:
        "Cottage home care is a spectacular agency with a great work environment. I can call any hour and there is always someone on the phone who will answer me and provide the help I need.",
      caseType: "CDPAP",
      rating: 5,
    },
    { src: "https://www.youtube.com/embed/NKOzRc6sJBE?rel=0" },
    {
      image: img2,
      name: "Sherry Fiedtkou",
      designation: "Patient",
      description:
        "I'm having the best experience working with Cottage Home Care. The staff and management is exceptional because I've been working in the Home care for 17 years and this is one of the best agencies.",
      caseType: "HHA",
      rating: 4.8,
    },
    { src: "https://www.youtube.com/embed/7xb2ChJBhh8?rel=0" },
    {
      image: img3,
      name: "Natividad Mateo",
      designation: "Patient",
      description:
        "Excellent customer service and employee service, always with a smile. Staff is caring and loving and receptive to your needs. Great home care agency to sign up with for work, or to receive Homecare services at home.",
      caseType: "CDPAP",
      rating: 5,
    },
    { src: "https://www.youtube.com/embed/_6QntUW0hjg?rel=0" },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 3,
    slidesToScroll: 1,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 768,
        settings: { slidesToShow: 1 },
      },
    ],
  };

  return (
    <div className="mb-6 md:my-8 ">
      <Slider {...settings}>
        {allData?.map((item, index) => (
          <div key={index} className="p-4">
            <div className="bg-white dark:bg-[#1C3460] rounded-xl  shadow-xl h-[360px] md:h-[465px] 2xl:h-[450px]">
              {item?.src ? (
                <VideoCard src={item?.src} />
              ) : (
                <TestimonialCard {...item} />
              )}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default React.memo(NewSlider);
