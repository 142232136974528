import React, { Suspense, useContext, useEffect, useState } from "react";
import ResourcesCard from "./ResourcesCard";
import Chat from "../../Shared/Chat/Chat";
import { toast } from "react-hot-toast";
import axios from "axios";
import SkeletonLoading from "../../Shared/Loading/SkeletonLoading";
import PageComponent from "../../PageComponent/PageComponent";

const Placeholder = () => (
  <div>
    <SkeletonLoading />
  </div>
);
const LazyBanner = React.lazy(() => import("./ResourceBanner"));

const Resources = () => {
  
  // const [pdfs, setPdfs] = useState([]);
  // const [load, setLoad] = useState(false);

  // useEffect(() => {
  //   fetchPdfs();
  // }, [load]);

  // console.log(pdfs)

  // const fetchPdfs = async () => {
  //   try {
  //     const response = await axios.get(
  //       "https://cottage-updated-server-v3.vercel.app/pdf"
  //     );
  //     setPdfs(response.data);
  //   } catch (error) {
  //     console.error("Error fetching PDFs", error);
  //   }
  // };

  // const viewPdf = (pdfData) => {
  //   const buffer = new Uint8Array(pdfData.data.data);
  //   const blob = new Blob([buffer], { type: "application/pdf" });
  //   const pdfUrl = URL.createObjectURL(blob);

  //   window.open(pdfUrl, "_blank");
  // };

  // const deleteHandler = (pdf) => {
  //   const proceed = window.confirm(
  //     `Are you sure, you want to delete ${pdf?.filename} ?`
  //   );
  //   if (proceed) {
  //     fetch(`https://cottage-updated-server-v3.vercel.app/pdf/${pdf?._id}`, {
  //       method: "DELETE",
  //     })
  //       .then((res) => res.json())
  //       .then((data) => {
  //         toast.success(data?.message);
  //         // console.log(data)
  //         setLoad(!load);
  //       });
  //   }
  // };




  // console.log(pdfs);

  return (
    <div>
      
      <PageComponent
        title="Resources - Cottage Home Care Services"
        description="Explore a wide range of resources at Cottage Home Care Services, including timesheets, Department of Health (DOH) guidelines, TIME-OFF forms, Direct Deposit Authorization forms, Physical Examination forms, and specialized timesheets like Daily and Celestial. These tools are designed to support our staff and ensure compliance with industry standards."
        keywords="Cottage Home Care resources, timesheet downloads, DOH guidelines, TIME-OFF form, direct deposit authorization, physical examination form, daily timesheet, celestial timesheet, healthcare compliance, employee support tools"
      />

      <Suspense fallback={<Placeholder />}>
        {/* Lazy-loaded banner */}
        <LazyBanner />
      </Suspense>

      <ResourcesCard
        // pdfs={pdfs}
        // viewPdf={viewPdf}
        // deleteHandler={deleteHandler}
       
      ></ResourcesCard>

          {/* <Chat></Chat> */}
    </div>
  );
};

export default Resources;
