import React, { useContext, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import useAdmin from "../Hooks/UseAdmin";
import { AuthContext } from "../Pages/Context/AuthProvider";
import Chat from "../Shared/Chat/Chat";
import AllBlog from "./AllBlog";
import BlogBanner from "./BlogBanner";
import PageComponent from "../PageComponent/PageComponent";
import useEditor from "../Hooks/useEditor";
import NewLoading from "../NewLoading";
import Categories from "./Categories";



const Blog = () => {
  const { user } = useContext(AuthContext);
  const [isAdmin] = useAdmin(user?.email);
  const [isEditor] = useEditor(user?.email);
  const [searchTerm, setSearchTerm] = useState(""); // State to hold the search term
  const [loading, setIsLoading] = useState(false); // State to handle loading
  const [buttonQuery, setButtonQuery] = useState("");
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(6);



  const url = `https://cottage-updated-server-v3.vercel.app/blogs/searchBlogsByTitle?keyword=${searchTerm}&category=${buttonQuery}&page=${page}&size=${size}`;

  const {
    data: { blogs, count } = [],
    isLoading: queryLoading,
    refetch,
  } = useQuery({
    queryKey: [
      "blogs",
      "searchBlogsByTitle",
      searchTerm,
      buttonQuery,
      page,
      size,
    ],
    queryFn: async () => {
      const res = await fetch(url);
      const data = await res.json();
      return data;
    },
  });

  const pages = Math.ceil(count / size);

  // Handle search when the search button is clicked
  const handleSearch = (inputValue) => {
    setIsLoading(true); // Start loading
    setTimeout(() => {
      setSearchTerm(inputValue); // Set search term based on input value
      setButtonQuery('');
      setIsLoading(false); // End loading after the search is complete
      window.scrollTo(0, 0);
    }, 1000); // Simulate a delay for the search process (can be removed in actual API call)
  };

  const handleButtonClick = (category) => {
    setButtonQuery(category);
    setSearchTerm('');
    window.scrollTo(0, 0); // Set the button query
  };

  return (
    <div className="min-h-screen dark:bg-slate-500">
      <PageComponent
        title="Blog - Cottage Home Care Services"
        description="Stay informed with the latest news, trends, and insights in the home care industry through our Cottage Home Care blog. Explore expert tips on caregiving, health and wellness, and senior care, along with success stories from our team and clients. Our blog is your go-to resource for understanding the importance of compassionate home care and staying connected to industry developments."
        keywords="home care blog, caregiving tips, senior care insights, health and wellness blog, Cottage Home Care news, home care industry updates, caregiver resources, home care trends, compassionate care stories, Cottage Home Care blog articles"
      />
      

      <BlogBanner />

      {queryLoading ? (
        <div className="py-5">
          <NewLoading />
        </div>
      ) : (
        <div>
          <div className="grid grid-cols-5 ">
            <div className="lg:col-span-3 col-span-full relative">
              {/* Conditionally render AllBlog only if no slug is present */}
             
                <AllBlog
                  blogs={blogs}
                  isAdmin={isAdmin}
                  isEditor={isEditor}
                  refetch={refetch}
                  handleSearch={handleSearch}
                  loading={loading}
                  searchTerm={searchTerm}
                  pages={pages}
                  page={page}
                  size={size}
                  setSize={setSize}
                  setPage={setPage}
                  handleButtonClick={handleButtonClick}
                />

            </div>

            {/* Categories component will remain visible even when viewing a single blog */}
            <div className="bg-[#EBF5F5] dark:bg-slate-600 lg:col-span-2 col-span-full pb-20">
              <Categories
                blogs={blogs}
                handleSearch={handleSearch}
                loading={loading}
                handleButtonClick={handleButtonClick}
              />
            </div>
          </div>
        </div>
      )}

      {/* <Chat /> */}
    </div>
  );
};

export default Blog;
