import React, { useState } from "react";
import "./SecondSlider.css";
import AllVideoSlider from "./AllVideoSlider";
import { NavLink } from "react-router-dom";
import CDPAPVideos from "./CDPAPVideos";
import Others from "./Others";

const SecondSlider = () => {
  const [activeTab, setActiveTab] = useState("FoodChallenge");


  const [ bgUrl, setBgUrl] = useState('https://www.youtube.com/embed/pIqVEKINXek?autoplay=1&mute=1&loop=1&controls=0&enablejsapi=1&iv_load_policy=3&modestbranding=1&playlist=pIqVEKINXek&playsinline=1&rel=0&showinfo=0')




  const getContent = () => {

    switch (activeTab) {
      case "CDPAP":
        return <CDPAPVideos 
        bgUrl={bgUrl}
        setBgUrl={setBgUrl}
        
        />;
        case "Others":
          return (
            <Others 
              bgUrl={bgUrl}
              setBgUrl={setBgUrl}
            />
          );
      default:
        return <AllVideoSlider 
        bgUrl={bgUrl}
        setBgUrl={setBgUrl}
        />;
    }
  };

  const url = bgUrl;

  return (
    <div
      className="video-block relative overflow-hidden"
      style={{ height: "88vh" }}
    >
      <iframe
        className="absolute top-0 left-0 w-full h-full"
        src={url}
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        title="Background Video"
      ></iframe>
      {/* Overlay Div */}
      <div className="overlay">
        <div className="text-white w-[95%] lg:w-[80%] 2xl:w-[70%] mx-auto mt-[10px] 2xl:mt-[40px] roboto-regular uppercase">
          <ul className="flex gap-3 md:gap-5 text-sm md:text-lg ">
            <li
              className={activeTab === "FoodChallenge" ? "active" : ""}
              onClick={() => setActiveTab("FoodChallenge")}
            >
              Food Challenge
            </li>
            <li
              className={activeTab === "CDPAP" ? "active" : ""}
              onClick={() => setActiveTab("CDPAP")}
            >
              CDPAP
            </li>
            <li
              className={activeTab === "Others" ? "active" : ""}
              onClick={() => setActiveTab("Others")}
            >
              Others
            </li>
          </ul>
        </div>
          
        <div className="route-section">{getContent()}</div>

      </div>
    </div>
  );
};

export default SecondSlider;
