import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  AreaChart,
  Area,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import NewLoading from "../NewLoading";

const AreaRechartComponent = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const selectedYear = selectedDate?.getFullYear();
  const selectedMonth = selectedDate?.getMonth() + 1; // getMonth() is 0-based

  const url = `https://cottage-updated-server-v3.vercel.app/count/dailyCount?year=${selectedYear}&month=${selectedMonth}`;

  const {
    data = [],
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["updateCount", selectedYear, selectedMonth],
    queryFn: async () => {
      const res = await fetch(url);
      const data = await res.json();
      return data;
    },
    enabled: !!selectedDate, // Only run query if a valid date is selected
  });

  const handleDateChange = (date) => {
    if (date) {
      setSelectedDate(date);
      refetch();
    }
  };

  if (isLoading) {
    return <NewLoading />;
  }

  return (
    <div>
      <div  style={{ marginBottom: "20px" }}>
        <label className=" dark:text-gray-100">Select Month: </label>
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          dateFormat="MM/yyyy"
          showMonthYearPicker
          showFullMonthYearPicker
          className="border focus:outline-none dark:border-gray-100 border-gray-400 rounded px-2 py-0.5 dark:bg-slate-500 dark:text-gray-100"
        />
      </div>
      <ResponsiveContainer width="100%" height={250}>
        <AreaChart
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="date" style={{ fontSize: "14px" }} />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Legend />
          <Area
            type="monotone"
            dataKey="visitors"
            stroke="#8884d8"
            fillOpacity={1}
            fill="url(#colorUv)"
          >
            <LabelList dataKey="visitors" position="top" />
          </Area>
          {/* Uncomment the following Area if you have more data to show */}
          {/* <Area
            type="monotone"
            dataKey="users"
            stroke="#82ca9d"
            fillOpacity={1}
            fill="url(#colorPv)"
          >
            <LabelList dataKey="users" position="top" />
          </Area> */}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AreaRechartComponent;
