import React from 'react';
import LazyLoad from 'react-lazyload';

const MyImageComponent = ({ src, alt, width, height, className }) => (
  <LazyLoad height={height} offset={100} once>
    <img
      src={src}
      alt={alt}
      width={width}
      height={height}
      className={className || ''} 
      
    />
  </LazyLoad>
);

export default MyImageComponent;
