import React, { useState, useRef, useEffect } from "react";
import { BiMenu } from "react-icons/bi"; // Example icon for menu
import { RiDashboardLine } from "react-icons/ri"; // Example icon for dashboard link
import { Link } from "react-router-dom";
import { MdLogin, MdLogout } from "react-icons/md";
const DropdownMenu = ({ user, isAdmin, isEditor, navColor, handleLogOut }) => {
  const [isOpen, setIsOpen] = useState(false); // Dropdown state (open/closed)
  const dropdownRef = useRef(null); // Reference to the dropdown

  // Toggle dropdown open/close
  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div ref={dropdownRef} className="relative " style={{ listStyle: "none" }}>
      <button
        type="button"
        onClick={toggleDropdown} // Toggle dropdown visibility
        className="text-4xl tracking-wide text-primary font-bold duration-200 bg-transparent outline-none cursor-pointer dark:text-gray-300 mt-1"
        style={{ listStyle: "none" }}
      >
        <BiMenu />
      </button>

      {isOpen && (
        <div className="p-2 py-4 space-y-2 shadow menu dropdown-content z-[1] bg-slate-200 dark:text-gray-100 rounded-box w-52 mt-3 dark:bg-slate-600 absolute right-0 font-semibold league-spartan">
          {user?.uid ? (
            <>
              <div className="flex items-center justify-start gap-2 pl-2">
                <img
                  src={user?.photoURL}
                  title={user?.displayName}
                  className="rounded-full w-10 h-10 object-cover border-primary border-[2px] p-[1px] dark:border-gray-100"
                  alt="user-Image"
                />
                <p className="text-[#49465D] text-lg dark:text-gray-100 ">{user?.displayName}</p>
              </div>

              <li
                className="nav-text  w-full rounded-md shadow-sm dark:border-b-[1px] dark:border-gray-300"
                style={{ boxShadow: "0 2px 2px rgba(0, 0, 0, 0.1)" }}
               
                onClick={handleLogOut}
              >
                <button
                  className=" hover:bg-[#DDDDDD]  font-medium text-lg tracking-wide text-[#49465D] transition-colors duration-200 uppercase dark:text-gray-100 flex items-center gap-5 dark:hover:text-white "
                  
                >
                 <MdLogout/> Sign Out
                </button>
              </li>
            </>
          ) : (
            <>
              <li className="" >
                <Link
                  to="/login"
                  aria-label="Sign Up"
                  title="Sign Up"
                  className="nav-text hover:bg-slate-300 w-full  "
                >
                  <button
                    className=" font-medium text-lg   tracking-wide text-[#49465D] transition-colors duration-200 uppercase dark:text-gray-100 flex items-center gap-5 dark:hover:text-white w-full"
                    
                  >
                    <MdLogin/> Sign In
                  </button>
                </Link>
              </li>
            </>
          )}

          {user?.uid && (
            <>
              {isAdmin && (
                <li style={{ listStyle: "none" }}>
                  <Link
                    to="/dashBoard"
                    aria-label="Dash Board"
                    title="Dash Board"
                    className="hover:bg-slate-300"
                  >
                    <p
                      className={`font-medium text-lg tracking-wide text-[#49465D] transition-colors duration-200 uppercase dark:text-gray-100 flex items-center gap-5 dark:hover:text-white`}
                    >
                      <RiDashboardLine className="text-2xl" />
                      Dash Board
                    </p>
                  </Link>
                </li>
              )}
              {isEditor && (
                <li style={{ listStyle: "none" }}>
                  <Link
                    to="/editorDashboard"
                    aria-label="Editor Dash Board"
                    title="Editor Dash Board"
                    className="hover:bg-slate-300"
                  >
                    <h1
                      className={` font-medium text-lg tracking-wide text-[#49465D] transition-colors duration-200 uppercase dark:text-gray-100 flex items-center gap-5 dark:hover:text-white`}
                    >
                      <RiDashboardLine className="text-2xl" />
                      Dash Board
                    </h1>
                  </Link>
                </li>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
