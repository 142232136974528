import slugify from "slugify";

// Base page mappings including Services and Community Outreach (no direct paths)
const pageMappings = {
  "/": { name: "Home", path: "/" },
  services: {
    name: "Services",
    path: "/services",  
    children: {
      "/cdpap": { name: "CDPAP", path: "/cdpap" },
      "/hha": { name: "HHA/PCA", path: "/hha" },
      "/nhtd": { name: "NHTD", path: "/nhtd" },
      "/personalPayService": { name: "Private Pay", path: "/personalPayService" },
    },
  },
  "/contacts": { name: "Contact Us", path: "/contacts" },
  "/hhaCertification": { name: "HHA Certification", path: "/hhaCertification" },
  "/resources": {
    name: "Resources",
    path: "/resources",
    children: {
      "/helpDesk": { name: "Help Desk", path: "/helpDesk" },
      "/covid": { name: "COVID-19", path: "/covid" },
      "/faqs": { name: "FAQs", path: "/faqs" },
    },
  },
  // Community Outreach grouping (no path)
  CommunityOutreach: {
    name: "Community Outreach",
    path: '/CommunityOutreach',
    children: {
      "/videos": { name: "Video Gallery", path: "/videos" },
      "/pastEvent": { name: "Past Events", path: "/pastEvent" },
      "/UpcomingEvent": { name: "Upcoming Events", path: "/UpcomingEvent" },
      "/team": { name: "Team Members", path: "/team" },
    },
  },
  "/blog": { name: "Blogs", path: "/blog" },
  "/missionVision": { name: "Mission & Vision", path: "/missionVision" },
  "/values": { name: "Values", path: "/values" },
  "/history": { name: "History", path: "/history" },
};



// Base path for blog slugs
const dynamicMappings = {
  blog: "/blogs/", // Adjust for dynamic blog slugs
};

// Fetch dynamic blog slugs
const fetchBlogSlugs = async () => {
  try {
    const response = await fetch("https://cottage-updated-server-v3.vercel.app/blogs"); // Adjust the endpoint as needed
    const data = await response.json();
    return data.blogs.map((blog) => ({
      slug: slugify(blog.title, { lower: true, strict: true }),
      title: blog.title,
    }));
  } catch (error) {
    console.error("Error fetching blog slugs:", error);
    return [];
  }
};

// Helper function to find the correct page in groupings
const findPageInGroupings = (path) => {
  for (const key in pageMappings) {
    if (pageMappings[key].children && pageMappings[key].children[path]) {
      return { parent: pageMappings[key], child: pageMappings[key].children[path] };
    }
  }
  return null;
};

// Generate Breadcrumb Data
export const generateBreadcrumbData = async () => {
  const navigationHistory = JSON.parse(localStorage.getItem("navigationHistory")) || [];
  const blogSlugs = await fetchBlogSlugs(); // Fetch dynamic blog slugs if needed
  const breadcrumbData = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": []
  };

  // Always add "Home" first
  breadcrumbData.itemListElement.push({
    "@type": "ListItem",
    "position": 1,
    "name": "Home",
    "item": "https://cottagehomecare.com/"
  });

  let positionCounter = 2;

  // Traverse the navigation history and generate breadcrumbs
  for (const path of navigationHistory) {
    let pageDetail = pageMappings[path] || findPageInGroupings(path);

    // If no page detail is found, handle dynamic blog paths
    if (!pageDetail && path.startsWith(dynamicMappings.blog)) {
      const slug = path.replace(dynamicMappings.blog, "");
      const blog = blogSlugs.find(b => b.slug === slug);
      pageDetail = blog ? { name: blog.title, path: `${dynamicMappings.blog}${slug}` } : null;
    }

    // Add breadcrumb for parent-child structure (e.g., Services or Community Outreach)
    if (pageDetail && pageDetail.parent) {
      // Parent
      breadcrumbData.itemListElement.push({
        "@type": "ListItem",
        "position": positionCounter++,
        "name": pageDetail.parent.name,
        "item": pageDetail.parent.path ? `https://cottagehomecare.com${pageDetail.parent.path}` : null
      });
      // Child
      breadcrumbData.itemListElement.push({
        "@type": "ListItem",
        "position": positionCounter++,
        "name": pageDetail.child.name,
        "item": `https://cottagehomecare.com${pageDetail.child.path}`
      });
    } else if (pageDetail) {
      // Standalone pages (like Contact Us, Blogs, etc.)
      breadcrumbData.itemListElement.push({
        "@type": "ListItem",
        "position": positionCounter++,
        "name": pageDetail.name,
        "item": pageDetail.path ? `https://cottagehomecare.com${pageDetail.path}` : null
      });
    }
  }
  // console.log(breadcrumbData)
  return breadcrumbData;
};

// Clear navigation history
export const clearNavigationHistory = () => {
  localStorage.removeItem("navigationHistory");
};
