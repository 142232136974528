import { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import useEditor from "../../Hooks/useEditor";
import { AuthContext } from "../Context/AuthProvider";
import Loading from "../../Shared/Loading/Loading";


const EditorRoute = ({ children }) => {
    const { user, loading } = useContext(AuthContext);
    const [isEditor, isEditorLoading] = useEditor(user?.email);
    const location = useLocation();

    if (loading || isEditorLoading) {
        return <Loading></Loading>
    }

    if (user && isEditor) {
        return children;
    }

    return <Navigate to="/login" state={{ from: location }} replace></Navigate>;
};

export default EditorRoute;