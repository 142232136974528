import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useQuery } from '@tanstack/react-query';
import Loading from "../Shared/Loading/Loading";
import { useContext } from "react";
import { AuthContext } from "../Pages/Context/AuthProvider";


const MyAllFiles = () => {

  const [message, setMessage] = useState("");
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(7);
  const{user} = useContext(AuthContext)

  const url = `https://cottage-updated-server-v3.vercel.app/uploadDetails/myFiles/${user?.email}?page=${page}&size=${size}`;
  const {
    data: { uploads, count } = [],
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["uploadDetails", page, size, user?.email],
    queryFn: async () => {
      const res = await fetch(url);
      const data = await res.json();
      return data;
    },
  });

  const pages = Math.ceil(count / size);
  // console.log(messages)


  if (isLoading) {
    return <Loading></Loading>;
  }

//   const reviewHandler = (file) => {
//     fetch(`https://cottage-updated-server-v3.vercel.app/uploadDetails/helpDesk/${file._id}`, {
//       method: "PUT",
//     })
//       .then((res) => res.json())
//       .then((data) => {
//         console.log(data);
//         if (data.modifiedCount > 0) {
//           toast.success("Review Successful");
//           refetch();
//           console.log("true");
//         }
//       });
//   };
//   const reverseHandler = (user) => {
//     fetch(
//       `https://cottage-updated-server-v3.vercel.app/uploadDetails/reverse/${user._id}`,
//       {
//         method: "PUT",
//       }
//     )
//       .then((res) => res.json())
//       .then((data) => {
//         console.log(data);
//         if (data.modifiedCount > 0) {
//           toast.success("Review Unsuccessful");
//           refetch();
//           console.log("true");
//         }
//       });
//   };

  // const deleteHandler = (file) => {
  //   console.log(file);
  //   const proceed = window.confirm('Are you sure you want to delete this file?');
    
  //   if (proceed) {
  //     fetch(`https://cottage-updated-server-v3.vercel.app/uploadDetails/${file._id}`, {
  //       method: "DELETE",
  //     })
  //     .then((res) => {
  //       if (!res.ok) {
  //         // If not successful, throw an error with the response text
  //         return res.text().then(text => { throw new Error(text) });
  //       }
  //       return res.json();
  //     })
  //     .then((data) => {
  //       if (data.deletedCount) {
  //         toast.success("File deleted successfully");
  //         refetch(); // Assuming refetch is a function to refresh the list
  //       } else {
  //         toast.error("Failed to delete the file");
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error deleting file:", error);
  //       toast.error("Error deleting file: " + error.message);
  //     });
  //   }
  // };
  

  return (
    <div className="border-t-2 min-h-screen">
      {
        uploads?.length > 0 ?
        <>
        <div className="overflow-x-auto">
        <table className="table w-full">
          <thead className="">
            <tr>
              <th className="dark:bg-slate-800 dark:text-gray-200">Serial</th>
              <th className="dark:bg-slate-800 dark:text-gray-200">Submitted By</th>
              <th className="dark:bg-slate-800 dark:text-gray-200">
                User Email
              </th>
             
              <th className="dark:bg-slate-800 dark:text-gray-200">
                Date & Time
              </th>
              <th className="dark:bg-slate-800 dark:text-gray-200">
               View DD
              </th>
              
              <th className="dark:bg-slate-800 dark:text-gray-200">Status</th>
            </tr>
          </thead>
          <tbody>
            {uploads?.map((file, index) => (
              <tr className="hover" key={file._id}>
                <th className="dark:bg-slate-500 dark:text-gray-100">
                  {index + 1}
                </th>
                <td className="dark:bg-slate-500 dark:text-gray-100">
                  {file?.name}
                </td>

                <td className="dark:bg-slate-500 dark:text-gray-100">
                  <p className="text-primary dark:text-gray-300">
                    {" "}
                    {file?.email}
                  </p>
                </td>
                

                <td className="dark:bg-slate-500 dark:text-gray-100">
                  <p className="text-primary dark:text-gray-300 text-sm font-medium">
                    {" "}
                    {new Date(file?.uploadTime).toLocaleString()}
                  </p>
                </td>
                <td className="dark:bg-slate-500 dark:text-gray-100">
                  <div className="flex items-center gap-2">
                  {
                    file?.filePaths?.map((path,index)=><a 
                    key={index}
                    href={path} 
                    target="_blank"  
                    rel="noopener noreferrer" 
                    className="text-white bg-primary px-4 py-1.5 rounded-md dark:text-gray-300 text-sm font-medium">
                     {" "}
                     View
                   </a>)
                  }
                  </div>
              
                  
                </td>
                {/* {user?.review === "true" ? (
                  <td className="dark:bg-slate-500 dark:text-gray-100 cursor-pointer">
                    <div
                      className="form-control"
                      onClick={() => reverseHandler(user)}
                    >
                      <label className="cursor-pointer label">
                        <input
                          type="checkbox"
                          checked="checked"
                          className="checkbox checkbox-primary "
                        />
                      </label>
                    </div>
                  </td>
                ) : (
                  <td className="dark:bg-slate-500 dark:text-gray-100 cursor-pointer">
                    <div
                      className="form-control"
                      onClick={() => reviewHandler(user)}
                    >
                      <label className="cursor-pointer label">
                        <input
                          type="checkbox"
                          checked="checked"
                          className="checkbox checkbox-warning "
                        />
                      </label>
                    </div>
                  </td>
                )} */}
                <td className="dark:bg-slate-500 dark:text-gray-100">
                 {
                  file?.status === false ?
                  <>
                  <p className="text-red-600 font-semibold ">pending</p>
                  
                  </>
                  :
                  <>
                    <p className=" text-xs font-semibold "> <span className="text-primary">Accepted by:</span> <span className="text-green-500">{file?.acceptedBy}</span></p>
                  </>
                 }
                </td>
              </tr>
            ))}
          </tbody>
        </table>

       
      </div>
      <div>
        <p className="text-center mt-10 text-lg font-semibold dark:text-gray-100">
          Currently Selected page:{" "}
          <span className="text-primary">{page + 1}</span>
        </p>
        <div className="pagination my-3 flex justify-center">

          {
            uploads?.length > 0 &&
            <>
              {[...Array(pages).keys()].map((number) => (
            <button
              key={number}
              className={
                page === number
                  ? "selected px-3 py-1   text-white ml-3 cursor-pointer custom-shadow"
                  : "px-3 py-1   text-gray-500 ml-3 cursor-pointer border-[1px] border-gray-300 hover:bg-[#444444] hover:text-white custom-shadow"
              }
              onClick={() => setPage(number)}
            >
              {number + 1}
            </button>
          ))}

            </>
            
          }
        
          <select
            className="ml-3 bg-white text-gray-500 border-[1px] border-gray-300 rounded-md focus:outline-none px-2"
            onChange={(event) => setSize(event.target.value)}
          >
            <option
              selected
              disabled
              className="hidden"
            >{`Page Size ${size}`}</option>

            <option value="7">Page Size 7</option>
            <option value="10">Page Size 10</option>
            <option value="15">Page Size 15</option>
            <option value="20">Page Size 20</option>
          </select>
        </div>
      </div>
        
        </>
        :
        <>

        <h1 className="text-center py-5 text-2xl font-semibold"> You didn't upload any files</h1>
        
        
        
        </>

      }
    </div>
  );
};

export default MyAllFiles;
