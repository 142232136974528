import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { FaUser } from 'react-icons/fa';
import { useQuery } from '@tanstack/react-query';
import Loading from '../../Shared/Loading/Loading';
import RegisterModal from './RegisterModal';

const RegisterUsers = () => {
    const [message, setMessage] = useState('')
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(7);

    const url = `https://cottage-updated-server-v3.vercel.app/registration?page=${page}&size=${size}`

    const { data: { allInfo, count } = [], isLoading, refetch } = useQuery({
        queryKey: ['users', page, size],
        queryFn: async () => {
            const res = await fetch(url);
            const data = await res.json();
            return data;
        }

    })


    const pages = Math.ceil(count / size);
    // console.log(messages)


    const messageHandler = (user) => {
        setMessage(user)

    }


    if (isLoading) {
        return <Loading></Loading>
    }

    const reviewHandler =(user)=>{


        fetch(`https://cottage-updated-server-v3.vercel.app/registration/${user._id}`, {
            method: 'PUT',

        })
            .then(res => res.json())
            .then(data => {

                console.log(data)
                if (data.modifiedCount > 0) {
                        toast.success('Review Successful')
                        refetch()
                        console.log("true")
                       
                }
            })
            

  


    }
    const reverseHandler =(user)=>{


        fetch(`https://cottage-updated-server-v3.vercel.app/registration/reverse/${user._id}`, {
            method: 'PUT',

        })
            .then(res => res.json())
            .then(data => {

                console.log(data)
                if (data.modifiedCount > 0) {
                        toast.success('Review Unsuccessful')
                        refetch()
                        console.log("true")
                       
                }
            })
            

  


    }


    const deleteHandler = (user) => {

        const proceed = window.confirm(
            `Are you sure, you want to delete ${user?.firstName} ?`
        );
        if (proceed) {
            fetch(`https://cottage-updated-server-v3.vercel.app/registration/${user?._id}`, {
                method: 'DELETE',

            })
                .then(res => res.json())
                .then(data => {
                    if (data.deletedCount) {
                        toast.success('User Delete SuccessFully')
                        refetch()

                    }


                })

        }
    }


    return (
        <div className='border-t-2'>

            <div className="overflow-x-auto">
                <table className="table w-full">

                    <thead className=''>
                        <tr>
                            <th className='dark:bg-slate-800 dark:text-gray-200'>Serial</th>
                            <th className='dark:bg-slate-800 dark:text-gray-200'>Name</th>
                            <th className='dark:bg-slate-800 dark:text-gray-200'>Email</th>
                            <th className='dark:bg-slate-800 dark:text-gray-200'>Phone</th>
                            <th className='dark:bg-slate-800 dark:text-gray-200'>Message</th>
                            <th className='dark:bg-slate-800 dark:text-gray-200'>Review</th>
                            <th className='dark:bg-slate-800 dark:text-gray-200'>Delete</th>

                        </tr>
                    </thead>
                    <tbody>

                        {
                            allInfo?.map((user, index) => <tr className="hover"
                                key={user._id}>
                                <th className='dark:bg-slate-500 dark:text-gray-100'>{index + 1}</th>
                                <td className='dark:bg-slate-500 dark:text-gray-100'>{user?.firstName}</td>

                                <td className='dark:bg-slate-500 dark:text-gray-100'>

                                    <a href={`mailto:${user?.email}`} className='text-primary dark:text-gray-300'> {user?.email}</a>

                                </td>
                                <td className='dark:bg-slate-500 dark:text-gray-100'>

                                    <a href={`tel:+1${user?.phone}`} className='text-primary dark:text-gray-300'> {user?.phone}</a>


                                </td>
                                <td className='dark:bg-slate-500 dark:text-gray-100'>

                                    <label


                                        onClick={() => messageHandler(user)}

                                        htmlFor="register-details" className="text-sm bg-primary py-2 px-2 rounded-md text-white shadow-lg cursor-pointer">
                                        See Message</label></td>
                                {

                                    user?.review === 'true' ?
                                    <td className='dark:bg-slate-500 dark:text-gray-100 cursor-pointer'
                                    
                                    
                                    
                                    > 
                                     <div className="form-control"
                                     onClick={()=>reverseHandler(user)}
                                     
                                     >
                                                <label className="cursor-pointer label">
                                                    <input type="checkbox" checked="checked" className="checkbox checkbox-primary " />
                                                </label>
                                            </div>
                                    
                                    </td>
                                           
                                        :
                                        
                                        <td className='dark:bg-slate-500 dark:text-gray-100 cursor-pointer'
                                        
                                        
                                        
                                        > 
                                        
                                        <div className="form-control"
                                        onClick={()=>reviewHandler(user)}
                                        >
                                        <label className="cursor-pointer label">
                                            
                                            <input type="checkbox" checked="checked" className="checkbox checkbox-warning " />
                                        </label>
                                        </div>
                                        
                                        </td>

                                        


                                }
                                <td className='dark:bg-slate-500 dark:text-gray-100'>
                                    <button

                                        onClick={() => deleteHandler(user)}

                                        className='btn btn-sm bg-red-600 uppercase text-white'>
                                        delete

                                    </button>

                                </td>



                            </tr>)
                        }
                    </tbody>
                </table>

                {
                    <RegisterModal
                        message={message}

                    />
                }
            </div>
            <div>
                <p className='text-center mt-10 text-lg font-semibold dark:text-gray-100'>Currently Selected page: <span className='text-primary'>{page + 1}</span></p>
                <div className='pagination my-3 flex justify-center'>
                    {
                        [...Array(pages).keys()].map(number => <button
                            key={number}
                            className={
                                page === number ? 'selected px-3 py-1   text-white ml-3 cursor-pointer custom-shadow'
                                    :
                                    'px-3 py-1   text-gray-500 ml-3 cursor-pointer border-[1px] border-gray-300 hover:bg-[#444444] hover:text-white custom-shadow'

                            }
                            onClick={() => setPage(number)}
                        >
                            {number + 1}


                        </button>)
                    }

                    <select className='ml-3 bg-white text-gray-500 border-[1px] border-gray-300 rounded-md focus:outline-none px-2' onChange={event => setSize(event.target.value)}>
                        <option selected disabled className='hidden'>{`Page Size ${size}`}</option>

                        <option value="7" >Page Size 7</option>
                        <option value="10"  >Page Size 10</option>
                        <option value="15" >Page Size 15</option>
                        <option value="20" >Page Size 20</option>

                    </select>


                </div>
            </div>


        </div>
    );
};

export default RegisterUsers;