import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import NewLoading from '../../../../NewLoading';


const Modal = ({office,setModalData}) => {

    const [loading, setLoading] = useState(false)
 
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    // console.log(user)

    const date = Date.now(); 


    const emailStorage = (email, name) => {
        localStorage.setItem("email", `${email}`);
        localStorage.setItem("name", `${name}`);
        localStorage.setItem('chatLength', 1);
    }

    const updateTime = (userMessage , email,name) =>{

        const info= {
            date,
            name,
            email: email,
            message :userMessage,
            chat : "active"
            
        }
    
        fetch(`https://cottage-updated-server-v3.vercel.app/users/time`, {
            method: "PUT",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify(info),
          })
            .then((res) => res.json())
            .then((data) => {
              //    console.log(data)
              if (data.acknowledged) {
                setLoading(false)
                toast.success('message send successfully')
              }
            });       
    
    }
    
    const saveUser = (name, email, verify = "false", chat = 'active',time=date) => {
        const newUser = { name, email, verify, chat,time };
        fetch('https://cottage-updated-server-v3.vercel.app/users', {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(newUser)
        })
            .then(res => res.json())
            .then(data => {
                //    console.log(data)
                if (data.acknowledged) {
                 
                    emailStorage(email, name)
                    reset()
                    setModalData(false)
                }

            })
    }

     

    const submitHandler = ( data)=>{
        setLoading(true)

        const name = data.name;
        const email = data.email;
        const userMessage = data.message;


        const message = {
            firstName: data.name,
            email: data.email,
            phone:data.phone,
            messages: data.message.replace(/<br>/g, '\n'),
            officeName: office?.name,
            time: date,
        };

        fetch(
            "https://cottage-updated-server-v3.vercel.app/chats",
            {
                method: "PUT",
                headers: {
                    "content-type": "application/json",
                },
                body: JSON.stringify(message),
            }
        )
            .then((res) => res.json())
            .then((data) => {
                if (data.acknowledged) {
                    

                    saveUser(name, email)

                    updateTime(userMessage,email,name)


                }
            });


    }


    return (
        <div >

            
            <input type="checkbox" id="booking-modal" className="modal-toggle" />
            <div className="modal ">
                <div className="modal-box relative bg-[#EBF8F9]">
                    {
                        loading&&
                        <div className='my-2'>
                            <NewLoading/>
                        </div>
                    }
                    <label htmlFor="booking-modal" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    <h3 className="text-lg font-bold uppercase">Contact The {office?.name}</h3>

                    <form 

                        onSubmit={handleSubmit(submitHandler)}
                    
                    className='grid grid-cols-1 gap-3 mt-10'>
                        
                        <div>
                            <input type="text"

                                {...register("name", {
                                    required: "Name is required",
                                })}

                                placeholder="Your name" className="input w-full  input-bordered"  />
                            {errors.name && (
                                <p className="text-red-600">{errors.name.message}</p>
                            )}
                        </div>
                        
                        <div>
                            <input 
                              
                                {...register("email", {
                                    required: "Email is required",
                                })}
                                
                                type="email" placeholder="Email Address" className="input w-full  input-bordered"/>
                            {errors.email && (
                                <p className="text-red-600">{errors.email.message}</p>
                            )}
                        </div>
                        <div>
                            <input 
                            htmlFor="phone"
                             type="tel"
                             id="phone"
                                {...register("phone", {
                                    required: "Phone Number is required",
                                })}
                                
                            placeholder="Your Phone Number" className="input w-full  input-bordered"/>
                            {errors.phone && (
                                <p className="text-red-600">{errors.phone.message}</p>
                            )}
                        </div>
                        <div>
                            <textarea 

                                {...register("message", {
                                    required: "Message is required",
                                })}
                                
                                className="textarea textarea-bordered w-full" placeholder="Message"></textarea>
                            
                            {errors.message && (
                                <p className="text-red-600">{errors.message.message}</p>
                            )}
                       </div>
                       
                       <br />
                        <input disabled={""} type="submit" value="submit" className="input w-full  input-bordered btn bg-primary hover:bg-[#2E2A4D] text-white" required />
                    </form>
                </div>
            </div>

        </div>
    );
};

export default Modal;