import React, { useEffect, useState } from "react";
import { Virtual, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./AllVideoSlider.css";
import { AllVideoSliderButton } from "../Services/Services/SliderButton/AllVideoSliderButton";

// Import images
import img1 from "../../../assets/slider/allVideos/cdpap1.jpg";

import VideosModal from "./VideosModal";




export default function App({bgUrl,setBgUrl}) {

  const backgroungd = 'https://www.youtube.com/embed/q_5BEK0CS8w?autoplay=1&mute=1&loop=1&controls=0&enablejsapi=1&iv_load_policy=3&modestbranding=1&playlist=q_5BEK0CS8w&playsinline=1&rel=0&showinfo=0'

  

  // console.log(bgUrl)


  useEffect(()=>{

    setBgUrl(backgroungd)


  },[backgroungd])

  
const allVideos = [
  {
    des: 'Caring Connections: Empowering Gracey and Aemi with Cottage Home Care | CDPAP',
    duration:'1:12',
    img: img1,
    youtubeUrl :"https://www.youtube.com/embed/q_5BEK0CS8w?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1"
  },
  
 
  

];

  const [youtubeUrl, setYoutubeUrl] = useState('')

  const [showModal, setShowModal] = useState(false);

  const openModal = (youtubeUrl) => {
    setShowModal(true);
    setYoutubeUrl(youtubeUrl

    )
  };

  const closeModal = () => {
    setShowModal(false);
  };


  return (
    <div>
      <div className="w-[95%] lg:w-[80%] 2xl:w-[70%] mx-auto text-white mt-[10px] md:mt-[20px] 2xl:mt-[30px] roboto-regular ">
        <h1 className="text-lg text-start md:text-xl 2xl:text-3xl font-semibold">Empowering Gracey and Aemi with Cottage Home Care</h1>
        <p className="mt-1 md:mt-3 w-[95%] lg:w-[90%] 2xl:w-[70%] text-[15px] text-sm md:text-base text-start ">
        Discover the warmth and comfort of personalized home care with Cottage Home Care's innovative approach. Our short animation, "Empowering Home Care with Cottage Home Care," introduces you to the Consumer Directed Personal Assistance Program (CDPAP), a service that puts you in control of your caregiving by allowing you to select someone you know and trust.
        </p>
        <p className="mt-2 2xl:mt-5 text-xl  lg:text-xl 2xl:text-2xl font-semibold text-start">
        Watch Now
        </p>
      </div>
      <div
        className="flex allVideo-swiper-page  relative pb-10"
        style={{ height: "88vh", overflow: "hidden" }}
      >
        <Swiper
          modules={[Virtual, Pagination]}
          slidesPerView={3}
          centeredSlides={false}
          spaceBetween={40}
          pagination={{
            type: "fraction",
          }}
          virtual
          grabCursor={true}
          className="w-[95%] lg:w-[80%] 2xl:w-[70%]  2xl:h-[330px] h-[280px] lg:h-[250px] all-videos "
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
          }}
        >
          {allVideos?.map((video, index) => (
            <SwiperSlide
            onClick={()=>openModal(video?.youtubeUrl)}
              className="  video-slide  h-[180px]  lg:h-[160px] 2xl:h-[220px] mt-[35px] md:mt-[45px] 2xl:mt-[45px]"
              key={index}
              virtualIndex={index}
            >
              <div
                className="video-image h-[180px] lg:h-[160px] 2xl:h-[220px] relative"
                style={{
                  backgroundImage: `url(${video?.img})`,
                  backgroundColor: "transparent",
                  backgroundSize: "cover",
                  width: "100%",
                }}
              >
                <p className="bg-[#000000d8] px-2 py-0.5 text-sm text-white absolute top-2 left-2">
                  {video?.duration}
                </p>

                <p className="video-description">{video.des}</p>
              </div>
             
            </SwiperSlide>
          ))}

          <div className="top-0 right-0 absolute">
            <AllVideoSliderButton />
          </div>
        </Swiper>
      </div>
      {showModal ? <VideosModal youtubeUrl={youtubeUrl} onClose={closeModal} /> : null}
    </div>
  );
}
