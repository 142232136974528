import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { generateBreadcrumbData } from "../Utils/breadcrumbUtils"; // Import necessary utilities
import { updateNavigationHistory } from "../Utils/navigationUtils";

const PageComponent = ({ title, description, keywords }) => {
  const location = useLocation(); // Get current route location
  const [breadcrumbData, setBreadcrumbData] = useState(null); // State to store breadcrumbs

  // Function to remove existing meta tags for description and keywords
  const clearMetaTags = () => {
    const metaTags = document.querySelectorAll(
      'meta[name="description"], meta[name="keywords"]'
    );
    metaTags.forEach((tag) => tag.remove());
  };

  useEffect(() => {
    // Clear previous meta tags when location changes
    clearMetaTags();

    // Update the navigation history with the current path
    updateNavigationHistory(location.pathname);

    // Fetch and set breadcrumb data based on the current path
    const fetchBreadcrumbData = async () => {
      try {
        const newBreadcrumbData = await generateBreadcrumbData(location.pathname);
        setBreadcrumbData(newBreadcrumbData);
      } catch (error) {
        console.error("Failed to fetch breadcrumb data:", error);
      }
    };

    fetchBreadcrumbData(); // Fetch the breadcrumb data
  }, [location.pathname]); // Trigger on location (route) change

  return (
    <div>
      {/* Dynamically update the page's title, description, and keywords */}
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description || "Default description"} />
        <meta name="keywords" content={keywords || "default, keywords"} />

        {/* Render structured data for breadcrumbs if available */}
        {breadcrumbData && (
          <script type="application/ld+json">
            {JSON.stringify(breadcrumbData)}
          </script>
        )}
      </Helmet>

     
    </div>
  );
};

export default PageComponent;
