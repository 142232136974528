import AOS from "aos";
import "aos/dist/aos.css";

import { useEffect} from "react";
import { Toaster } from "react-hot-toast";
import { RouterProvider } from "react-router-dom";
import Route from "../src/Components/Routes/Route/Route";
import "./App.css";
import ScrollButton from "./Components/Pages/ScrollButton/ScrollButton";


function App() {

  const localCount = sessionStorage.getItem("count");

  useEffect(() => {

    AOS.init();
    AOS.refresh();

    if (!localCount) {
      fetch("https://cottage-updated-server-v3.vercel.app/count", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ count: 1 }),
      })
        .then((res) => res.json())
        .then((data) => {
          sessionStorage.setItem("count", true);
        });
    }
  }, [localCount]);

  return (
    <div>
      <RouterProvider router={Route}></RouterProvider>
      <Toaster></Toaster>
      <ScrollButton></ScrollButton>
    </div>
  );
}

export default App;
