// ToastNotification.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';

const showNotification = (message) => {
  if (Notification.permission === 'granted') {
    console.log('NOTIFICATION IS DISPLAYING')
    new Notification('New Notification', {
      body: message,
      icon: 'https://res.cloudinary.com/dlw7u1u5p/image/upload/v1729001155/pt0yoocyiwimobfqr0nk.jpg' // Optional icon
    });
  }
};

const ToastNotification = () => {
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchBlogs = async () => {
    try {
      const res = await axios.get('https://cottage-updated-server-v3.vercel.app/blogs');
      setBlogs(res?.data?.blogs);
      setIsLoading(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (Notification.permission === 'default') {
      Notification.requestPermission().then(permission => {
        if (permission !== 'granted') {
          console.warn('Notification permission denied');
        }
      });
    }

    fetchBlogs();
    const interval = setInterval(fetchBlogs, 60000); // Fetch every 60 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  useEffect(() => {
    if (blogs?.length > 0) {
      const pendingBlogs = blogs?.filter(blog => blog.status === 'pending');
      if (pendingBlogs?.length > 0) {
        showNotification(`You have ${pendingBlogs.length} pending blog(s)`);
      }
    }
  }, [blogs]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading blogs: {error.message}</div>;

  return null;
};

export default ToastNotification;