import React from 'react';

const NewLoading = () => {
    return (

        <div className="flex items-center justify-center space-x-2 ">

            <div className=" w-8 h-8 md:w-12 md:h-12 border-4 border-dashed rounded-full animate-spin border-white"></div>

        </div>

    );
};

export default NewLoading;