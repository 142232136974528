import React from 'react';

const Loading = () => {
    return (

        <div className="flex items-center justify-center space-x-2 my-5">

            <div className="w-16 h-16 border-4 border-dashed rounded-full animate-spin border-primary"></div>

        </div>

    );
};

export default Loading;