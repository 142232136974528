import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import navLogo from "../../../assets/vector-logo.png";
import useAdmin from "../../Hooks/UseAdmin";
import { AuthContext } from "../Context/AuthProvider";
import "./Nav.css";
import SideNav from "./SideNav";
import useEditor from "../../Hooks/useEditor";
import MyImageComponent from "../../Utils/MyImageComponent";
import DropdownMenu from "./DropdownMenu";
import { ThemeContext } from "../Context/ThemeContext";
import ToggleButton from "./ToggleButton";

const NavBar = () => {
  const [customShadow, setCustomShadow] = useState("shadow-none");
  const [isSticky, setIsSticky] = useState(false);
  const [navColor] = useState(false);
  const { user, logOut } = useContext(AuthContext);
  const { theme, setTheme } = useContext(ThemeContext);
  const [isAdmin] = useAdmin(user?.email);
  const [isEditor] = useEditor(user?.email);

  // console.log(isEditor, isAdmin);

  // console.log(isAdmin)
  const handleLogOut = () => {
    logOut()
      .then(() => {})
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 5) {
        setIsSticky(true);
        setCustomShadow("shadow-xl");
      } else if (window.scrollY < 5) {
        setIsSticky(false);
        setCustomShadow("shadow-none");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarClasses = `bg-white z-50   sticky   ${
    isSticky
      ? "top-0 transition-all duration-1000"
      : "-top-20 transition-all duration-1500 "
  } `;

  return (
    <div
      className={`  ${navbarClasses}  md:z-50 dark:bg-slate-800  ${customShadow}  `}
    >
      <div
        className="px-4 pt-2 pb-4 md:mx-auto nav-size ml-auto"
        id="nav-shape"
      >
        <div className=" flex items-center md:justify-between md:w-full w-[95%] ">
          <Link
            to="/"
            aria-label="Cottage Home Care Services"
            title="Cottage Home Care Services"
            className="inline-flex items-center  "
          >
            <div>
              <div className="md:flex items-center hidden ">
                <MyImageComponent
                  src={navLogo}
                  className="nav-img shadow-2xl p-2 border-2 border-primary rounded-full dark:bg-gray-300"
                  alt="Cottage Home Care Services Logo"
                  width="100" // Set an appropriate width
                  height="100" // Set an appropriate height
                />
                <h5
                  className={` ml-4   text-[#00a6b2]  nav-font league-spartan font-bold`}
                >
                  <span className="text-shadow ">
                    Cottage Home Care Services
                  </span>
                  <hr className="border-[1px] -mt-0.5 border-primary dark:border-gray-100 md:w-full w-[85%]" />
                  <p className="font-semibold top-title md:text-center text-[#49465D] tracking-tighter md:tracking-normal dark:text-gray-300 md:ml-0 ml-2 ">
                    THE RIGHT HOME CARE FOR YOU
                  </p>
                </h5>
              </div>

              <div className="header-container flex items-center md:hidden  ">
                <MyImageComponent
                  src={navLogo}
                  className="logo shadow-2xl border-2 border-primary rounded-full dark:bg-gray-300"
                  alt="Cottage Home Care Services Logo"
                  width="70"
                  height="70"
                />

                <div className="text-container ml-3 league-spartan">
                  <h5 className="title  text-[#00A6B2] tracking-wide  font-bold uppercase ">
                    Cottage Home <br /> Care Services
                  </h5>
                  <p className="  dark:border-gray-100 mb-[1px] border-t-[1px] border-gray-700" />
                  <p className="tagline  font-semibold text-[#49465D] dark:text-gray-100 mt-[1px]">
                    THE RIGHT HOME CARE FOR YOU
                  </p>
                </div>
              </div>
            </div>
          </Link>

          <ul className=" items-center hidden space-x-4 lg:flex   nav-list">
            <li style={{ listStyle: "none" }}>
              <Link
                to="/"
                aria-label="Home"
                title="Home"
                className={`font-semibold    tracking-wide text-[#49465D] transition-colors duration-200  
                                ${
                                  navColor && "customWhite"
                                } white   uppercase nav-text hover-underline-animation  dark:text-gray-300`}
              >
                Home
              </Link>
            </li>
            {/* <li>
              <NavLink
                to="/services"
                aria-label="services"
                title="services"
                className={`font-semibold    tracking-wide text-[#49465D] transition-colors duration-200  
                                ${
                                  navColor && "customWhite"
                                } white   uppercase nav-text hover-underline-animation dark:text-gray-300`}
              >
                Services
              </NavLink>
            </li> */}

            <li className="relative group" style={{ listStyle: "none" }}>
              <button
                className={`dropdown-toggle font-semibold    tracking-wide text-[#49465D] transition-colors duration-200  
                                ${
                                  navColor && "customWhite"
                                } white   uppercase nav-text hover-underline-animation dark:text-gray-300 `}
              >
                Services
              </button>
              <div className="dropdown-content group-hover:block text-base   rounded-sm w-full pt-2  dark:bg-slate-800 dark:hover:text-gray-100  font-semibold">
                {/* <Link to="/services">
                  {" "}
                  <p className="dark:text-gray-100 dark:hover:text-gray-700 uppercase ">
                    Overview
                  </p>
                </Link> */}
                <Link to="/cdpap">
                  {" "}
                  <p className="dark:text-gray-100 dark:hover:text-gray-700 uppercase">
                    CDPAP
                  </p>
                </Link>
                <Link to="/hha">
                  <p className="dark:text-gray-100 dark:hover:text-gray-700 uppercase">
                    hha/pca
                  </p>{" "}
                </Link>
                <Link to="/nhtd">
                  <p className="dark:text-gray-100 dark:hover:text-gray-700 uppercase">
                    NHTD
                  </p>{" "}
                </Link>
                <Link to="/personalPayService">
                  <p className="dark:text-gray-100 dark:hover:text-gray-700 uppercase">
                    Private Pay
                  </p>{" "}
                </Link>
              </div>
            </li>

            <li style={{ listStyle: "none" }}>
              <Link
                to="/contacts"
                aria-label="Contact US"
                title="Contact US"
                className={`font-semibold    tracking-wide text-[#49465D] transition-colors duration-200  
                                ${
                                  navColor && "customWhite"
                                } white   uppercase nav-text hover-underline-animation dark:text-gray-300`}
              >
                Contact US
              </Link>
            </li>

            <li style={{ listStyle: "none" }}>
              <Link
                to="/hhaCertification"
                aria-label="HHA Certification"
                title="HHA Certification"
                className={`font-semibold    tracking-wide text-[#49465D] transition-colors duration-200  
                                ${
                                  navColor && "customWhite"
                                } white   uppercase nav-text hover-underline-animation dark:text-gray-300`}
              >
                HHA Certification
              </Link>
            </li>

            <li
              className="relative group font-semibold"
              style={{ listStyle: "none" }}
            >
              <button
                className={`dropdown-toggle font-semibold    tracking-wide text-[#49465D] transition-colors duration-200  
                                ${
                                  navColor && "customWhite"
                                } white   uppercase nav-text hover-underline-animation dark:text-gray-300 `}
              >
                Resources
              </button>
              <div className="dropdown-content group-hover:block text-base  rounded-sm w-full pt-2  dark:bg-slate-800 dark:hover:text-gray-100 ">
                <Link to="/resources">
                  {" "}
                  <p className="dark:text-gray-100 dark:hover:text-gray-700">
                    Resources
                  </p>
                </Link>
                <Link to="/helpDesk">
                  {" "}
                  <p className="dark:text-gray-100 dark:hover:text-gray-700">
                    Help Desk
                  </p>
                </Link>
                <Link to="/covid">
                  <p className="dark:text-gray-100 dark:hover:text-gray-700">
                    Covid-19
                  </p>{" "}
                </Link>
                <Link to="/faqs">
                  <p className="dark:text-gray-100 dark:hover:text-gray-700">
                    FAQs
                  </p>{" "}
                </Link>
              </div>
            </li>
            <li
              className="relative group font-semibold"
              style={{ listStyle: "none" }}
            >
              <button
                className={`dropdown-toggle font-semibold    tracking-wide text-[#49465D] transition-colors duration-200  
                                ${
                                  navColor && "customWhite"
                                } white   uppercase nav-text hover-underline-animation dark:text-gray-300 `}
              >
                Community Outreach
              </button>
              <div className="dropdown-content group-hover:block text-base  rounded-sm w-full pt-2  dark:bg-slate-800">
                <Link to="/videos">
                  <p className="dark:text-gray-100 dark:hover:text-gray-700">
                    Video Gallery
                  </p>
                </Link>
                <Link to="/pastEvent">
                  {" "}
                  <p className="dark:text-gray-100 dark:hover:text-gray-700">
                    Past Events
                  </p>
                </Link>
                <Link to="/UpcomingEvent">
                  <p className="dark:text-gray-100 dark:hover:text-gray-700">
                    Upcoming Events
                  </p>
                </Link>
                <Link to="/team">
                  <p className="dark:text-gray-100 dark:hover:text-gray-700">
                    Team Members
                  </p>
                </Link>
              </div>
            </li>

            <li style={{ listStyle: "none" }}>
              <Link
                to="/blog"
                aria-label="Blog"
                title="Blog"
                className={`font-semibold    tracking-wide text-[#49465D] transition-colors duration-200  
                                ${
                                  navColor && "customWhite"
                                } white   uppercase nav-text hover-underline-animation dark:text-gray-300`}
              >
                Blog
              </Link>
            </li>

            <li>
              <ToggleButton
              setTheme={setTheme}
              theme={theme}
              
              />
            </li>



            <DropdownMenu user={user} isAdmin={isAdmin} isEditor={isEditor} navColor={navColor} handleLogOut={handleLogOut} />
          </ul>

          <div className="lg:hidden">
            <div className="  transition duration-200 rounded focus:outline-none focus:shadow-outline z-50 absolute right-5 top-5 ">
              <SideNav />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
