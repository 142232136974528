import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  LabelList
} from "recharts";

import NewLoading from '../NewLoading';

const Rechart = () => {
  const [newData, setNewData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const url = `https://cottage-updated-server-v3.vercel.app/count/monthlyCount?year=${selectedYear}`;

  const {
    data = [],
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["monthlyVisitors", selectedYear],
    queryFn: async () => {
      const res = await fetch(url);
      const data = await res.json();
      return data;
    },
  });

  useEffect(() => {
    if (data.length > 0) {
      const updatedData = data.map((da) => {
        if (da?.month === "July, 2023") {
          return {
            ...da,
            visitors: da.visitors + 672
          };
        } else {
          return da;
        }
      });

      // Only update state if the data has changed
      if (JSON.stringify(updatedData) !== JSON.stringify(newData)) {
        setNewData(updatedData);
      }
    }
  }, [data, newData]);

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    refetch();
  };

  if (isLoading) {
    return <NewLoading/>;
  }

  return (
    <div>
      <div style={{ marginBottom: "10px" }}>
        <label className='dark:text-gray-100' htmlFor="yearPicker">Select Year: </label>
        <select
          id="yearPicker"
          value={selectedYear}
          onChange={handleYearChange}
          className='border  dark:border-gray-100 border-gray-400 rounded px-2 py-0.5 dark:bg-slate-500 dark:text-gray-100'
        >
          {/* You can customize this list of years as needed */}
          {[...Array(10)].map((_, i) => {
            const year = new Date().getFullYear() - i;
            return <option key={year} value={year}>{year}</option>;
          })}
        </select>
      </div>
      <ResponsiveContainer width={"100%"} height={300}>
        <BarChart
          data={newData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="visitors" fill="#82ca9d">
            <LabelList dataKey="visitors" position="top" />
          </Bar>
          <Bar dataKey="users" fill="#8884d8">
            <LabelList dataKey="users" position="top" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Rechart;
