import React, { useState } from "react";
import CdpapModal from "../../CdpapModal/CdpapModal";
import { Link, NavLink } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import Chat from "../../Shared/Chat/Chat";
import { TbFileTypePdf } from "react-icons/tb";
import contactPicture from "../../../assets/NHTD/contact.jpg";
import youtbeThumnail from "../../../assets/NHTD/thumnail.webp";
import allNHTD from "../../../assets/Brochures/NHTD/allNHTD.pdf";
import cottageBrushers from "../../../assets/Brochures/Cottage/CottageBrushers.pdf";
import NHTDcover from "../../../assets/NHTD/NHTD-cover.jpg";

import "./NHTD.css";
import Faq from "./Faq";
import PageComponent from "../../PageComponent/PageComponent";
import ModalBody from "../../CustomModal/ModalBody";
import { FaPlayCircle } from "react-icons/fa";

const NHTD = () => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const youtubeUrl = "https://www.youtube.com/embed/2jZGWEI1iOw?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1"

  const allServices = [
    {
      servicesName: "CDPAP",
      path: "cdpap",
    },
    {
      servicesName: "HHA/PCA",
      path: "hha",
    },
    {
      servicesName: "NHTD",
      path: "nhtd",
    },
    // {
    //   servicesName: "Private Case",
    //   path: "privatePay",
    // },
  ];

  const stuffs = [
    {
      value: "900",
      content: "Active HHA Aide",
    },
    {
      content: "Extensive Experience",
    },
    {
      content: "Expert Medical Team",
    },
  ];

  const handleOpenPdfClick = (pdf) => {
    // Replace this with your actual PDF file or provide a way for the user to select a file
    window.open(pdf, "_blank");
  };

  return (
    <div className="min-h-screen dark:bg-gray-600">
      <PageComponent
        title=" NHTD - Cottage Home Care Services"
        description="Our Nursing Home Transition and Diversion (NHTD) program empowers individuals with disabilities and seniors to live independently with dignity. Supported by experienced Home Health Aides (HHA) and Personal Care Aides (PCA), our NHTD services include personalized care plans tailored to individual needs—covering activities like bathing, dressing, meal preparation, and medication management. We are dedicated to enhancing the quality of life for our clients across New York City, Nassau, Suffolk County, Westchester, and Albany."
        keywords="NHTD, Nursing Home Transition and Diversion, Home Health Aides, Personal Care Aides, disability support, senior independence, personalized care plans, home care services, New York City, Nassau, Suffolk County, Westchester, Albany, compassionate care, living independently."
      />

      {/* banner section start  */}

      <div className="singleService-banner min-h-[40vh] league-spartan">
        <h1 className="ml-5 md:ml-0   text-2xl md:text-xl lg:text-2xl xl:text-4xl text-white pt-[10vh] mb-5 font-bold text-shadow text-center md:max-w-md  lg:ml-[5%]">
          NHTD
          <p className="mt-3 ">Nursing Home</p>
          <p className="mt-3 ">Transition and Diversion</p>
        </h1>
        <div className="flex justify-center pl-5 my-2 md:max-w-md lg:ml-[5%]">
          <label
            htmlFor="cdpap-form"
            className="cursor-pointer  md:text-lg font-medium md:font-semibold px-2.5 py-1.5 md:px-4  border-b-4 border-r-4 border-white md:py-3 bg-primary text-white shadow-md rounded-md text-base "
          >
            Contact Us
          </label>
        </div>
      </div>

      {/* banner section end  */}

      {/* details service section start  */}

      <div className="2xl:w-[75%] lg:w-[90%] w-[95%] mx-auto mt-10 pb-20">
        <div className="grid md:grid-cols-7 gap-5 md:gap-0">
          {/* left side column start */}

          <div className="md:col-span-2  ">
            <div className="bg-[#F8F9FA]  rounded-md py-8 dark:bg-slate-800">
              <div className="w-[90%] mx-auto league-spartan">
                <h1 className="txt-lg md:text-2xl font-semibold dark:text-gray-100">
                  All Services
                </h1>

                <div className="mt-3 nhtd-section">
                  {allServices?.map((service, index) => (
                    <div key={index}>
                      <NavLink
                        to={`/${service?.path}`}
                        className=" flex items-center gap-1 px-3 py-2.5 rounded-md bg-white  shadow-lg my-3 hover:bg-primary hover:text-white"
                      >
                        <IoIosArrowForward className="font-semibold text-xl" />{" "}
                        {service?.servicesName}
                      </NavLink>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* contact card start  */}

            <div className=" my-5">
              <div
                className="rounded-md league-spartan"
                style={{
                  background: `linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%), url(${contactPicture})`,
                  height: "350px",
                  width: "100%",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <h1
                  className="text-white text-2xl lg:text-4xl text-center playrify  w-[full]  flex justify-center items-center
                 pt-[120px]"
                >
                  Need Any Help ?
                </h1>
                <a
                  href="tel:+1516-367-2266"
                  className="text-white text-center playrify text-2xl lg:text-4xl mt-2 block"
                >
                  +1516-367-2266
                </a>
                <div className="flex justify-center mt-5">
                  <Link
                    to="/contacts"
                    className="bg-primary text-white px-4 lg:py-4 py-2 rounded-md  text-lg lg:text-2xl playrify "
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>

            <div className=" my-5 rounded-md bg-[#f1f1f1] lg:h-[300px] dark:bg-slate-800">
              <div className="  px-4 py-8 ">
                <h1 className="playrify font-bold text-gray-600 text-lg lg:text-2xl dark:text-gray-100 league-spartan">
                  Downloads
                </h1>
                <h3 className="mt-3 dark:text-gray-100 text-[16px] leading-relaxed open-sans font-normal">
                  To download all the important documents regarding the needs of
                  home healthcare, please click the buttons.
                </h3>

                <div className="my-5 open-sans">
                  <button
                    onClick={() => handleOpenPdfClick(allNHTD)}
                    className=" my-3 bg-primary text-white px-4 py-2 rounded-md flex gap-1 items-center"
                  >
                    <TbFileTypePdf className="text-xl" /> NHTD Brochures
                  </button>

                  <button
                    onClick={() => handleOpenPdfClick(cottageBrushers)}
                    className=" my-3 bg-primary text-white px-4 py-2 rounded-md flex gap-1 items-center"
                  >
                    <TbFileTypePdf className="text-xl md:text-base lg:text-xl" />{" "}
                    Company Details
                  </button>
                </div>
              </div>
            </div>

            {/* contact card end  */}
          </div>

          {/* left side colum end  */}

          {/* right side colum start  */}

          <div className="md:col-span-5 order-first md:order-none ">
            <div className="w-[95%] mx-auto  min-h-[100vh]">
              {/* banner start  */}

              <div className="lg:h-[500px] 2xl:h-[550px] overflow-hidden ">
                <img
                  className=" h-full w-full object-cover"
                  src={NHTDcover}
                  alt={NHTDcover}
                  w='100'
                  h='100'
                />
              </div>

              {/* banner end  */}

              <h1 className="mt-5 league-spartan text-xl lg:text-4xl font-semibold dark:text-gray-100 text-primary">
                What Is NHTD ?
              </h1>

              <ul className="list-disc ml-5 mt-3 lg:text-lg dark:text-white space-y-2 md:space-y-0 open-sans">
                <li>
                  It's a program to help people live in their own homes instead
                  of staying in a nursing home.
                </li>

                <li>
                  It helps by giving exceptional care and tools to make a living
                  at home more accessible and safer.
                </li>
              </ul>

              <div className="grid grid-cols-1 md:grid-cols-3 md:gap-7 mt-5">
                {stuffs?.map((stuff, index) => (
                  <div
                    key={index}
                    className="shadow-xl border-b-4 border-primary rounded-md px-4 py-6 dark:bg-slate-200 text-primary league-spartan"
                    style={{ boxShadow: "0 10px 15px 3px rgba(0, 0, 0, 0.3)" }}
                  >
                    {stuff?.value && stuff?.content && (
                      <h1 className="lg:text-5xl text-4xl text-primary text-center font-semibold ">
                        {stuff?.value}+
                      </h1>
                    )}
                    {!stuff?.value && stuff?.content && (
                      <h3 className=" text-center text-xl lg:text-2xl font-semibold mt-3">
                        {stuff?.content}
                      </h3>
                    )}
                    {stuff?.value && stuff?.content && (
                      <h3 className=" text-center text-xl lg:text-xl font-semibold mt-3">
                        {stuff?.content}
                      </h3>
                    )}
                  </div>
                ))}
              </div>

              <div className="my-5">
                <h1 className="pt-3 playrify text-xl lg:text-3xl font-semibold dark:text-gray-100 league-spartan text-primary">
                  Why Choose NHTD ?
                </h1>

                <ul className="list-disc ml-5 mt-3 lg:text-lg dark:text-white space-y-2 md:space-y-0 open-sans">
                  <li>
                    You get to stay close to your family and friends at home.
                  </li>

                  <li>
                    You can feel more comfortable and happy in a familiar place.
                  </li>
                  <li>
                    Peace of Mind knowing one of our certified caregivers cares
                    for you.
                  </li>
                </ul>
              </div>

              <div className=" md:flex justify-between gap-5">
                <div
                  className=" h-[250px] md:h-[300px] w-full"
                  style={{
                    background: `linear-gradient(180deg, rgba(0, 166, 178, 0.123) 0%, rgba(0, 166, 178, 0.116) 100%), url(${youtbeThumnail})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <div className="  flex justify-center h-full items-center">
                    <button
                      onClick={openModal}
                      className="play-btn cursor-pointer"
                    >
                      <FaPlayCircle className='text-3xl lg:text-4xl'/>
                    </button>

                    {showModal ? <ModalBody youtubeUrl={youtubeUrl} onClose={closeModal} /> : null}
                  </div>
                </div>

                <div className="w-full">
                  <h1 className="playrify text-xl lg:text-2xl tracking-wider font-semibold dark:text-gray-100 mt-3 md:mt-0 league-spartan text-primary">
                    Eligibility for NHTD
                  </h1>

                  <ul className="list-disc ml-5 mt-3 lg:text-lg dark:text-white space-y-2 md:space-y-1 open-sans">
                    <li>
                      Must be eligible for the nursing home level of care (5 or
                      more in the USA)
                    </li>

                    <li>
                      If under 65, must have a diagnosis of physical disability
                    </li>
                  </ul>
                </div>
              </div>

              <div className="my-5">
                <h1 className="pt-3 playrify text-xl lg:text-4xl font-semibold dark:text-gray-100 mb-3 league-spartan text-primary">
                  Frequently Asked Questions
                </h1>

                <Faq />
              </div>
            </div>
          </div>

          {/* right side colum end  */}
        </div>
      </div>

      {/* details service section end  */}

      {/* contact modal start  */}

     

      <CdpapModal />
      

      {/* contact modal end  */}

      {/* chat boat import  */}
      {/* <Chat /> */}

      {/* chat boat import end  */}
    </div>
  );
};

export default NHTD;
