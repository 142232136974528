import Lottie from 'lottie-react';
import React from 'react';
import reader from '../../../src/assets/Lotty File/Animation - 1707842080201.json';

const WebLoading = () => {
    return (
        <div className='fixed top-0 left-0 w-full h-full bg-white flex items-center justify-center z-50'>
            <Lottie
                animationData={reader}
                loop={true}
                className='md:w-[40%] mx-auto'
            />
        </div>
    );
}

export default WebLoading;
