import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useLoaderData, useNavigate } from "react-router-dom";
import OverlayLoading from "../../OverlayLoading/OverlayLoading";

function SingleResources() {
  const pdf = useLoaderData();
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [filename, setFilename] = useState(pdf?.filename || "");

  const [loading, setLoading] = useState(false)

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFilenameChange = (event) => {
    setFilename(event.target.value);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      toast.error("Please select a file");
      return;
    }

    setLoading (true)

    const formData = new FormData();
    formData.append("pdf", selectedFile);
    formData.append("filename", filename);

    try {
      const response = await axios.put(
        `https://cottage-updated-server-v3.vercel.app/pdf/${pdf._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        setLoading(false)
        toast.success("PDF updated successfully");
        navigate("/resources");
      }
    } catch (error) {
        setLoading(false)
      console.error("Error updating PDF:", error);
      toast.error("Error updating PDF");
    }
  };

  return (
    <div className="border-2 min-h-screen">
        {
            loading &&
            <OverlayLoading/>
        }
      <div className="flex justify-center mt-[10vh]">
        <div className="w-[95%] md:w-[50%] bg-gray-50 p-8 rounded-md shadow-xl dark:bg-slate-600">
          <p className="text-xl text-center Poppins font-semibold my-3 dark:text-gray-200 text-primary">
            Update the PDF for {pdf?.filename}
          </p>

          <div className="space-y-2">
            <div className="space-y-0.5 text-sm">
              <div className="flex items-center gap-10">
                <label htmlFor="file" className="block dark:text-gray-200">
                  Select the PDF File
                </label>
                <label className="block dark:text-gray-200 text-red-500 text-sm font-semibold">
                  PDF Should not be larger than 100KB
                </label>
              </div>

              <input
                type="file"
                accept=".pdf"
                onChange={handleFileChange}
                className="w-full px-4 py-3 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
                focus:bg-white shadow-md dark:bg-gray-400 dark:text-gray-200 dark:placeholder:text-gray-100"
              />
            </div>
            <div className="space-y-0.5 text-sm">
              <label htmlFor="filename" className="block dark:text-gray-200">
                File Name
              </label>
              <input
                value={filename}
                onChange={handleFilenameChange}
                placeholder="File Name"
                className="w-full px-4 py-3 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
                focus:bg-white shadow-md dark:bg-gray-400 dark:text-gray-200 dark:placeholder:text-gray-100"
              />
            </div>
          </div>

          <hr className="my-3" />

          <button
            onClick={handleUpload}
            className="block w-full p-3 text-center rounded-sm bg-primary hover:bg-secondary text-white mt-6"
          >
            Upload
          </button>
        </div>
      </div>
    </div>
  );
}

export default SingleResources;
