import React from 'react';
import { useSwiper } from 'swiper/react';
import { AiOutlineArrowRight} from 'react-icons/ai';
import { AiOutlineArrowLeft} from 'react-icons/ai';
import './VideoSliderButton.css'

export const VideoSliderButton = () => {
  const swiper = useSwiper();

  return (
    <div className="swiper-nav-btns  text-red-600 font-bold   justify-center border-2 z-50">
      <button onClick={() => swiper.slidePrev()} className='md:px-5 md:py-3 px-3 py-1   preview-button text-shadow2 text-white text-xl font-semibold'><AiOutlineArrowLeft/></button>
      <button onClick={() => swiper.slideNext()} className='md:px-5 md:py-3 px-7 py-1  next-button text-shadow2 text-white text-xl font-semibold'><AiOutlineArrowRight/></button>
    </div>
  );
};