import { useEffect, useState } from "react"

const useEditor = email => {

    const [isEditor, setIsEditor] = useState(false);
    const [isEditorLoading, setEditorLoading] = useState(true)

    useEffect(() => {
        if (email) {
            fetch(`https://cottage-updated-server-v3.vercel.app/editor/${email}`)
                .then(res => res.json())
                .then(data => {
                    // console.log(data)
                    setIsEditor(data?.isEditor);
                    setEditorLoading(false)

                })
        }
    }, [email])
    return [isEditor, isEditorLoading]

}

export default useEditor;