import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Carousel.css'; // Import your custom Tailwind styles
import './InsuranceCarousel.css';

import centerspan from '../../../../../src/assets/insurance/40507361449.png';
import riverSpring from '../../../../../src/assets/insurance/riverspring.jpg';
import blueCrossShield from '../../../../../src/assets/insurance/blue-cross-shield.jpg';
import eldersPlan from '../../../../../src/assets/insurance/eldersplan.jpg';
import feledis from '../../../../../src/assets/insurance/fedelis.jpg';
import hemaspic from '../../../../../src/assets/insurance/hemaspic.jpg';
import centerlight from '../../../../../src/assets/insurance/centerlight.jpg';
import senior from '../../../../../src/assets/insurance/seniorHolwHealth.jpg';
import vns from '../../../../../src/assets/insurance/vns.jpg';

const InsuranceCarousel = () => {
  const images = [centerspan, riverSpring, blueCrossShield, eldersPlan,feledis,hemaspic,centerlight,senior,vns,centerspan, riverSpring, blueCrossShield,];

  const settings = {
    dots: true,
    infinite: true,
    speed: 5000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3, // Adjust slidesToShow as needed for mobile devices
        },
      },
     
    ],
  };

  return (
    <div className="w-full mx-auto my-8">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="outline-none focus:outline-none">
            <img src={image} alt={`carousel-item-${index}`} className="w-full h-auto" w='100' h='100' />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default InsuranceCarousel;
