import React from "react";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";

// Cottage Celebrity Brunch

import brunch1 from "../../../src/assets/celebrity-brunch/img-1.jpg";
import brunch2 from "../../../src/assets/celebrity-brunch/img-2.jpg";
import brunch3 from "../../../src/assets/celebrity-brunch/img-3.jpg";
import brunch4 from "../../../src/assets/celebrity-brunch/img-4.jpg";
import brunch5 from "../../../src/assets/celebrity-brunch/img-5.jpg";
import brunch6 from "../../../src/assets/celebrity-brunch/img-6.jpg";
import brunch7 from "../../../src/assets/celebrity-brunch/img-7.jpg";
import brunch8 from "../../../src/assets/celebrity-brunch/img-8.jpg";
import brunch9 from "../../../src/assets/celebrity-brunch/img-9.jpg";
import brunch10 from "../../../src/assets/celebrity-brunch/img-10.jpg";
import brunch11 from "../../../src/assets/celebrity-brunch/img-11.jpg";
import brunch12 from "../../../src/assets/celebrity-brunch/img-12.jpg";
import brunch13 from "../../../src/assets/celebrity-brunch/img-13.jpg";
import brunch14 from "../../../src/assets/celebrity-brunch/img-14.jpg";
import brunch15 from "../../../src/assets/celebrity-brunch/img-15.jpg";
import brunch16 from "../../../src/assets/celebrity-brunch/img-16.jpg";
import brunch17 from "../../../src/assets/celebrity-brunch/img-17.jpg";
import brunch18 from "../../../src/assets/celebrity-brunch/img-18.jpg";
import PageComponent from "../PageComponent/PageComponent";

const CelebrityBrunch = () => {
  return (
    <div className="min-h-screen dark:bg-slate-600">

      <PageComponent
        title="Celebrity Brunch - Cottage Home Care Services"
        description="Join Cottage Home Care Services for a memorable Celebrity Brunch event filled with fun, food, and special guests. Celebrate with our community, enjoy entertainment, and engage with local celebrities as we highlight the importance of compassionate home care. Don’t miss this exciting event where we come together to support and uplift our clients and caregivers across New York City, Nassau, Suffolk County, Westchester, and Albany"

        keywords="Celebrity Brunch, Cottage Home Care event, community celebration, local celebrities, client appreciation event, caregiver appreciation, entertainment event, New York City, Nassau, Suffolk County, Westchester, Albany, home care event, community engagement"
      />

      <div className="celebrity-bruch min-h-[80vh] hidden md:block">
        <h1 className="lg:pt-[55vh] pt-[60vh] text-center text-white  text-2xl md:text-5xl font-semibold">
          <span className="graduation-title px-4 py-2 league-spartan text-shadow">
            Cottage Home Care Celebrity Brunch
          </span>
        </h1>
      </div>
      <div className="celebrity-bruch block md:hidden">
        <h1 className="pt-[30vh] text-center text-white  text-2xl md:text-5xl font-semibold">
          <span className="graduation-title px-4 py-2 league-spartan text-shadow">
            Cottage Home Care Celebrity Brunch
          </span>
        </h1>
      </div>
      <div className="lg:py-10 lg:w-[80%] w-[95%] mx-auto ">
        <div className="lg:mb-8 lg:pt-12 py-5">
          <h1 className="text-center  text-xl  league-spartan md:text-xl lg:text-2xl xl:text-4xl font-semibold dark:text-gray-100 text-primary">
            {" "}
            Cottage Home Care Celebrity Brunch
          </h1>
          <div className="flex justify-center ">
            <hr className="bg-primary px-5 md:mt-5 mt-2 py-[1px] w-[100px]" />
          </div>
        </div>
        <div className="grid md:grid-cols-2 gap-7">
          <div class="grid grid-rows-3 grid-flow-col gap-7">
            <div
              class="row-span-1 col-span-4 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointer"
              data-aos="flip-right"
              data-aos-duration="1000"
            >
              <PhotoProvider>
                <PhotoView src={brunch1}>
                  <img
                    src={brunch1}
                    alt="img-1"
                    className="w-full h-[100%] shadow-md border-[1px] border-primary  object-cover custom-zoom center "
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class=" row-span-1 col-span-2 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointer "
              data-aos="flip-left"
              data-aos-duration="1200"
            >
              <PhotoProvider>
                <PhotoView src={brunch2}>
                  <img
                    src={brunch2}
                    alt="img2"
                    className="w-full h-[100%] shadow-md border-[1px] border-primary  object-cover custom-zoom"
                  ></img>
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class="row-span-1 col-span-2 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointer "
              data-aos="flip-right"
              data-aos-duration="1400"
            >
              <PhotoProvider>
                <PhotoView src={brunch3}>
                  <img
                    src={brunch3}
                    alt="img3"
                    className="w-full h-[100%] shadow-md border-[1px] border-primary  object-cover custom-zoom"
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class="row-span-2 col-span-2 lg:h-[628px] h-[428px]  overflow-hidden w-full cursor-pointer"
              data-aos="flip-right"
              data-aos-duration="1600"
            >
              <PhotoProvider>
                <PhotoView src={brunch5}>
                  <img
                    src={brunch5}
                    alt=""
                    className="w-full h-[100%] shadow-md border-[1px] border-primary cursor-pointer object-cover custom-zoom center "
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
          </div>

          <div class="grid grid-rows-3 grid-flow-col gap-7">
            <div
              class="row-span-1 col-span-2 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointe"
              data-aos="flip-right"
              data-aos-duration="1000"
            >
              <PhotoProvider>
                <PhotoView src={brunch4}>
                  <img
                    src={brunch4}
                    alt="img-1"
                    className="w-full h-[100%] shadow-md border-[1px] border-primary cursor-pointer object-cover custom-zoom"
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class="row-span-1 col-span-4 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointe"
              data-aos="flip-left"
              data-aos-duration="1200"
            >
              <PhotoProvider>
                <PhotoView src={brunch7}>
                  <img
                    src={brunch7}
                    alt="img-1"
                    className="w-full h-[100%] shadow-md border-[1px] border-primary cursor-pointer object-cover custom-zoom "
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class="row-span-1 col-span-2 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointer "
              data-aos="flip-left"
              data-aos-duration="1400"
            >
              <PhotoProvider>
                <PhotoView src={brunch6}>
                  <img
                    src={brunch6}
                    alt="img-1"
                    className="w-full h-[100%] shadow-md border-[1px] border-primary cursor-pointer  object-cover custom-zoom"
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class="row-span-1 col-span-2 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointe "
              data-aos="flip-right"
              data-aos-duration="1400"
            >
              <PhotoProvider>
                <PhotoView src={brunch8}>
                  <img
                    src={brunch8}
                    alt="img-1"
                    className="w-full  h-[100%] shadow-md border-[1px] border-primary cursor-pointer object-cover custom-zoom"
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class="row-span-1 col-span-2  lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointer  "
              data-aos="flip-right"
              data-aos-duration="1600"
            >
              <PhotoProvider>
                <PhotoView src={brunch9}>
                  <img
                    src={brunch9}
                    alt="img-1"
                    className=" h-[100%] w-full shadow-md border-[1px] border-primary cursor-pointer object-cover custom-zoom"
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
          </div>
        </div>
        <div className="grid md:grid-cols-2 gap-7 mt-[28px]">
          <div class="grid grid-rows-3 grid-flow-col gap-7">
            <div
              class="row-span-1 col-span-4 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointer"
              data-aos="flip-right"
              data-aos-duration="1000"
            >
              <PhotoProvider>
                <PhotoView src={brunch10}>
                  <img
                    src={brunch10}
                    alt="img-1"
                    className="w-full h-[100%] shadow-md border-[1px] border-primary  object-cover custom-zoom center "
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class=" row-span-1 col-span-2 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointer "
              data-aos="flip-left"
              data-aos-duration="1200"
            >
              <PhotoProvider>
                <PhotoView src={brunch11}>
                  <img
                    src={brunch11}
                    alt="img2"
                    className="w-full h-[100%] shadow-md border-[1px] border-primary  object-cover custom-zoom"
                  ></img>
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class="row-span-1 col-span-2 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointer "
              data-aos="flip-right"
              data-aos-duration="1400"
            >
              <PhotoProvider>
                <PhotoView src={brunch12}>
                  <img
                    src={brunch12}
                    alt="img3"
                    className="w-full h-[100%] shadow-md border-[1px] border-primary  object-cover custom-zoom"
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class="row-span-2 col-span-2 lg:h-[628px] h-[428px]  overflow-hidden w-full cursor-pointer"
              data-aos="flip-right"
              data-aos-duration="1600"
            >
              <PhotoProvider>
                <PhotoView src={brunch13}>
                  <img
                    src={brunch13}
                    alt=""
                    className="w-full h-[100%] shadow-md border-[1px] border-primary cursor-pointer object-cover custom-zoom center "
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
          </div>

          <div class="grid grid-rows-3 grid-flow-col gap-7">
            <div
              class="row-span-1 col-span-2 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointe"
              data-aos="flip-right"
              data-aos-duration="1000"
            >
              <PhotoProvider>
                <PhotoView src={brunch14}>
                  <img
                    src={brunch14}
                    alt="img-1"
                    className="w-full h-[100%] shadow-md border-[1px] border-primary cursor-pointer object-cover custom-zoom"
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class="row-span-1 col-span-4 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointe"
              data-aos="flip-left"
              data-aos-duration="1200"
            >
              <PhotoProvider>
                <PhotoView src={brunch16}>
                  <img
                    src={brunch16}
                    alt="img-1"
                    className="w-full h-[100%] shadow-md border-[1px] border-primary cursor-pointer object-cover custom-zoom "
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class="row-span-1 col-span-2 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointer "
              data-aos="flip-left"
              data-aos-duration="1400"
            >
              <PhotoProvider>
                <PhotoView src={brunch15}>
                  <img
                    src={brunch15}
                    alt="img-1"
                    className="w-full h-[100%] shadow-md border-[1px] border-primary cursor-pointer  object-cover custom-zoom"
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class="row-span-1 col-span-2 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointe "
              data-aos="flip-right"
              data-aos-duration="1400"
            >
              <PhotoProvider>
                <PhotoView src={brunch17}>
                  <img
                    src={brunch17}
                    alt="img-1"
                    className="w-full  h-[100%] shadow-md border-[1px] border-primary cursor-pointer object-cover custom-zoom"
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class="row-span-1 col-span-2  lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointer  "
              data-aos="flip-right"
              data-aos-duration="1600"
            >
              <PhotoProvider>
                <PhotoView src={brunch18}>
                  <img
                    src={brunch18}
                    alt="img-1"
                    className=" h-[100%] w-full shadow-md border-[1px] border-primary cursor-pointer object-cover custom-zoom"
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CelebrityBrunch;
