import React from "react";

const EditorDashBoard = () => {
  return <div className="min-h-screen border-2">


    <h1 className="flex justify-center items-center text-2xl mt-[30vh] dark:text-gray-100">Editor Dashboard Is coming soon</h1>
    
    
    </div>;
};

export default EditorDashBoard;
