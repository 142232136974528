import React, { useContext } from "react";
import { FaUserNurse, FaUsers } from "react-icons/fa";
import { GiLovers } from "react-icons/gi";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";

import { useQuery } from '@tanstack/react-query';
import Loading from "../Shared/Loading/Loading";
import Rechart from "./Rechart";
import RoundChart from "./RoundChart";
import { Link } from "react-router-dom";
import AreaRechartComponent from "./AreaChart";
import { AuthContext } from "../Pages/Context/AuthProvider";

const GraphPage = () => {
  const {previousVisitors,visitors } = useContext(AuthContext)

  // console.log(visitors)

  let presentVisitors = 0;

  if(visitors){

    for(const visitor of visitors){
      presentVisitors = presentVisitors + visitor?.visitor
    }


  }

  // console.log(presentVisitors)



  ///////
   
  const url = `https://cottage-updated-server-v3.vercel.app/notification`;

  const {
    data: allData = [],
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["notifications"],
    queryFn: async () => {
      const res = await fetch(url);
      const data = await res.json();
      return data;
    },
  });

  let pca = 0;
  let cdpap = 0;
  let officeMessage = 0;

  // console.log(allData?.messages)

  if (allData?.length <= 0) {
    return <Loading></Loading>;
  } else {
    for (const service of allData?.messages) {
      if (service?.service === "PCA") {
        pca = pca + 1;
      } else if (service?.service === "CDPAP") {
        cdpap = cdpap + 1;
      } else if (service?.officeName)  {
        officeMessage = officeMessage + 1;
      }
    }
  }

  return (
    <div className="border-2  dark:border-[#E5E7EB] min-h-screen">
      <div className="md:flex gap-10 items-center">
        <div className="w-[100%] mx-auto md:ml-[4%]">
          <div className="w-[95%] mx-auto grid grid-cols-2 lg:grid-cols-5 mt-5 gap-7 items-center ">
            <Link to='/dashboard/cottageUsers'>
            <div className="  bg-gradient-to-r from-sky-500 to-indigo-500 text-primary-content rounded-xl py-4 shadow-md">
              <div>
                <p className="flex justify-center  text-4xl text-gray-100 ">
                  <FaUsers></FaUsers>
                </p>
                <p className="flex justify-center font-semibold text-gray-100 gap-2 items-center">
                  Total Users:
                  <span className="text-xl"> {allData?.users?.length}</span>
                </p>
              </div>
            </div>
            
            </Link>
            <Link to='/dashboard/pca'>
            <div className="rounded-xl bg-gradient-to-r from-sky-500 to-indigo-500 text-primary-content py-4 shadow-md">
              <div className="">
                <p className="flex justify-center  text-4xl text-gray-100 ">
                  <FaUserNurse></FaUserNurse>
                </p>
                <p className="flex justify-center font-semibold text-gray-100 gap-2 items-center">
                  PCA Inquiry :<span className="text-xl"> {pca}</span>
                </p>
              </div>
            </div>
            </Link>
            <Link to='/dashboard/cdpap'>
            <div className=" rounded-xl bg-gradient-to-r from-sky-500 to-indigo-500 text-primary-content py-4 shadow-md">
              <div className="">
                <p className="flex justify-center  text-4xl text-gray-100 ">
                  <GiLovers></GiLovers>
                </p>
                <p className="flex justify-center font-semibold text-gray-100 gap-2 items-center">
                  CDPAP Inquiry:<span className="text-xl"> {cdpap}</span>
                </p>
              </div>
            </div>
            </Link>
            <Link to='/dashboard/offices'>
            <div className=" rounded-xl bg-gradient-to-r from-sky-500 to-indigo-500 text-primary-content py-4 shadow-md">
              <div className="">
                <p className="flex justify-center  text-4xl text-gray-100 ">
                  <HiOutlineBuildingOffice2></HiOutlineBuildingOffice2>
                </p>
                <p className="flex justify-center font-semibold text-gray-100 gap-2 items-center">
                  Office Messages:<span className="text-xl"> {officeMessage}</span>
                </p>
              </div>
            </div>
            </Link>
            <div>
              <div className="lg:flex items-center gap-3 hidden">
                <p className="bg-gradient-to-r from-sky-500 to-indigo-500 text-white w-14 h-14 rounded-full flex items-center justify-center shadow-lg">
                  <FaUsers className="text-3xl"></FaUsers>{" "}
                </p>

                <div>
                  <p className="text-gray-500 font-medium text-sm dark:text-gray-200">
                    Total Visitors
                  </p>
                  <p className="flex justify-center  font-semibold text-gray-600 dark:text-gray-200">
                    {previousVisitors + presentVisitors }
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-3 lg:hidden justify-center">
                <p className="bg-gradient-to-r from-sky-500 to-indigo-500 text-white w-14 h-14 rounded-full flex items-center justify-center shadow-lg">
                  <FaUsers className="text-3xl"></FaUsers>{" "}
                </p>

                <div>
                  <p className="text-gray-500 font-medium text-sm dark:text-gray-200">
                    Total Visitors
                  </p>
                  <p className="flex justify-center  font-semibold text-gray-600 dark:text-gray-200">
                    {previousVisitors + presentVisitors }
                  </p>
                </div>
              </div>
        </div>
      </div>
      <div className=" lg:ml-[2%] lg:flex  mt-16  gap-10 ">
        <div className="lg:w-[50%] w-full   ">
          <Rechart 
          
          ></Rechart>
        </div>
        <div className=" w-full lg:w-[50%]  md:flex md:justify-center md:mt-5 lg:mt-0 lg:justify-start items-center ml-[3%]">
        
        <div className="flex justify-center  mt-5 md:block md:mt-0">
        <RoundChart
            pca={pca}
            cdpap={cdpap}
            officeMessage={officeMessage}
          ></RoundChart>
          

       
        </div>

        <div className="flex justify-center -mt-10  md:block md:mt-0 ">
        <div>
            <div className="flex gap-2 items-center my-2">
              <p className="flex bg-[#00C49F] px-2 py-2"></p>
              <p className="font-medium text-sm text-gray-600 dark:text-gray-200">
                - PCA Message
              </p>
            </div>
            <div className="flex gap-2 items-center my-2">
              <p className="flex bg-[#0088FE] px-2 py-2"></p>
              <p className="font-medium text-sm text-gray-600 dark:text-gray-200">
                - CDPAP Message
              </p>
            </div>
            <div className="flex gap-2 items-center my-2">
              <p className="flex bg-[#FFBB28] px-2 py-2"></p>
              <p className="font-medium text-sm text-gray-600 dark:text-gray-200">
                - Office Message
              </p>
            </div>
          </div>
        </div>
        </div>
      </div>
      <div className="mt-12 w-[95%] mx-auto">
        <AreaRechartComponent
   
        ></AreaRechartComponent>
      </div>
      

    </div>
  );
};

export default GraphPage;
