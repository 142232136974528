import React from "react";
import { CgMenuGridR } from "react-icons/cg";
import { ImBlog } from "react-icons/im";
import { AiOutlineHome } from "react-icons/ai";
import { MdOutlineDashboard } from "react-icons/md";
import { Link, NavLink, Outlet } from "react-router-dom";
import useTitle from "../Components/Hooks/useTitle";
import NavBar from "../Components/Pages/NavBar/NavBar";
import Footer from "../Components/Shared/Footer/Footer";
import { PiHandDepositFill } from "react-icons/pi";

const EditorLayOut = () => {
  useTitle("Dashboard");

  return (
    <div className="min-h-screen ">
      <div className="sticky top-0 z-50">
        <NavBar></NavBar>
        <div className="py-3 block lg:hidden dark:bg-slate-600">
          <label
            htmlFor="editorDashboard-drawer"
            className=" drawer-button text-4xl text-primary"
          >
            <CgMenuGridR />
          </label>
        </div>
      </div>
      <div className="drawer  lg:drawer-open ">
        <input
          id="editorDashboard-drawer"
          type="checkbox"
          className="drawer-toggle"
        />
        <div className="drawer-content dark:bg-slate-600">
          <Outlet></Outlet>
        </div>
        <div className="drawer-side lg:border-2  dark:border-[#E5E7EB] w-[70%] lg:w-fit bg-white lg:bg-[#EBF8F9]  lg:shadow-lg dark:bg-slate-600 dark:text-gray-100 ">
          <label
            htmlFor="editorDashboard-drawer"
            className="drawer-overlay"
          ></label>

          <ul className="menu p-4 w-80 text-base-content mt-5 ">
            <li className="my-1 dark:text-gray-100  text-lg text-center">
              <Link to="/" className="flex items-center gap-4">
                <AiOutlineHome></AiOutlineHome> Home
              </Link>
            </li>
            <li className="my-1 dark:text-gray-100  text-lg text-center">
              <Link to="/editorDashboard" className="flex items-center gap-4">
                <MdOutlineDashboard></MdOutlineDashboard> DashBoard
              </Link>
            </li>

            {/* <li className="my-1 dark:text-gray-100 text-lg text-center ">
                <Link to="/editorDashboard/blogForm" className="flex items-center gap-4">

                  <ImBlog /> Add A Blog

                </Link>
              </li> */}

            <li className="my-1 dark:text-gray-100 text-lg text-center">
              <NavLink
                to="/editorDashboard/myDD"
                className="flex items-center gap-4"
              >
                <PiHandDepositFill />My Submissions
              </NavLink>
            </li>
            <li className="my-1 dark:text-gray-100 text-lg text-center">
              <NavLink
                to="/editorDashboard/addDD"
                className="flex items-center gap-4"
              >
                <PiHandDepositFill /> Submit Direct Deposit
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default EditorLayOut;
