import React, { Suspense } from "react";
import HhhaCertificationAbout from "./HhhaCertificationAbout";
import HhhaContactForm from "./HhhaContactForm";
import HhhaSchoolMap from "./HhhaSchoolMap";
import WelcomeMessage from "./WelcomeMessage";
import Chat from "../../Shared/Chat/Chat";
import SkeletonLoading from "../../Shared/Loading/SkeletonLoading";
import PageComponent from "../../PageComponent/PageComponent";

const Placeholder = () => <div><SkeletonLoading/></div>;
const LazyBanner = React.lazy(() => import('./HhhaBanner'));

const HhhaCertification = () => {
 
  return (
    <div className="dark:bg-slate-600">
      <PageComponent
        title="HHA Certification- Cottage Home Care Services"
        description="Earn your Home Health Aide (HHA) certification through the Brooklyn Institute of Vocational Training. Our comprehensive training programs equip you with the skills necessary to provide exceptional care in the home care industry. Enroll today and start your career in healthcare services with Cottage Home Care Services."
        keywords="HHA Certification, Home Health Aide Training, Brooklyn Institute of Vocational Training, healthcare training, home care industry training, Cottage Home Care Services, healthcare careers, vocational training in Brooklyn"
      />
      <Suspense fallback={<Placeholder />}>
        {/* Lazy-loaded banner */}
        <LazyBanner />
      </Suspense>
      <WelcomeMessage> </WelcomeMessage>
      <HhhaCertificationAbout></HhhaCertificationAbout>
      <HhhaSchoolMap></HhhaSchoolMap>
      <HhhaContactForm></HhhaContactForm>
          {/* <Chat></Chat> */}
    </div>
  );
};

export default HhhaCertification;
