import React from "react";
import LazyLoadVideo from "../../LazyloadVideo.js/LazyLoadVideo";

const CottageVideoModal = ({ onClose, youtubeUrl }) => {
  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div
  className="fixed inset-0 bg-black bg-opacity-80 lg:bg-opacity-60 flex justify-center z-50"
  onClick={onClose}
>
  <div
    data-aos="fade-down"
    data-aos-duration="1000"
    className="relative dark:bg-slate-600 w-[95%] md:max-w-2xl 2xl:max-w-4xl top-[35%] mf:top-[30%] lg:top-[25%]"
    onClick={handleModalClick}
    id="food-video"
    style={{
      position: "fixed",
      left: "50%",
      transform: "translate(-50%, -50%)",
    }}
  >
    <div>
      <div className="w-full shadow-md z-50">
        <LazyLoadVideo src={youtubeUrl} rounded={false}></LazyLoadVideo>
      </div>
    </div>
  </div>
</div>
  );
};

export default CottageVideoModal;
