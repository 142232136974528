// ScrollToTop.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useScroll } from '../Pages/Context/ScrollContext';


const ScrollToTop = () => {
  const { pathname } = useLocation();
  const { resetScrollBehavior } = useScroll();

  useEffect(() => {
    window.scrollTo(0, 0);
    resetScrollBehavior(); // Reset scroll behavior to auto on every route change
  }, [pathname, resetScrollBehavior]);

  return null;
};

export default ScrollToTop;
