export const updateNavigationHistory = (currentPath) => {
  // Get the current navigation history or initialize as an empty array
  let navigationHistory = JSON.parse(localStorage.getItem("navigationHistory")) || [];

  // Normalize the current path (optional, based on your use case)
  const normalizedPath = currentPath.endsWith('/') ? currentPath.slice(0, -1) : currentPath;

  // Avoid adding the current path if it's the same as the last recorded path
  if (navigationHistory.length === 0 || navigationHistory[navigationHistory.length - 1] !== normalizedPath) {
    navigationHistory.push(normalizedPath);  // Add new path
  }

  // Limit the history to the last 10 pages
  const maxHistoryLength = 10;
  navigationHistory = navigationHistory.slice(-maxHistoryLength);

  // Store the updated navigation history back in localStorage
  localStorage.setItem("navigationHistory", JSON.stringify(navigationHistory));
};
